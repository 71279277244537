import {
  createTheme,
  Grid,
  TableCell,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  GRAYNeutralCONTENTS,
  GRAYPALETITLE,
  GRAYNeutralDISABLED,
  REDTITLE,
  GREENACCENT,
  GREENTITLE,
  ORANGECONTENTS,
  BLUECONTENTS,
  REDCONTENTS,
  GRAYPALEBORDER,
  GRAYPALECONTENTS,
  ORANGETITLE,
} from "./color";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";

export const theme = createTheme({
  palette: {
    primary: {
      light: "#CC1F3B",
      main: "#ffffff",
      dark: "#182031",
      contrastText: "#000000",
    },
  },
});

export const ContentShift = styled("div")(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  padding: theme.spacing(1),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: "200ms",
  }),
}));

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: "1rem",
    maxWidth: 700,
  },
}));

export const ModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "80%",
  maxWidth: "80rem",
  bgcolor: "background.paper",
  border: "0px",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  borderRadius: "5px",
};

export const ModalMobileStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  maxWidth: "80rem",
  bgcolor: "background.paper",
  border: "0px",
  p: 1,
  boxShadow: 24,
  borderRadius: "5px",
};

export const MidiumModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "60%",
  maxWidth: "600px",
  bgcolor: "background.paper",
  border: "0px",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  borderRadius: "5px",
};

export const SmallModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "30rem",
  bgcolor: "background.paper",
  border: "0px",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  borderRadius: "5px",
};

// 메인화면
export const TopTitleBox = styled(Grid)({
  marginLeft: "1.6rem",
  fontSize: "3.5rem",
});

export const MainTopTitleBox = styled(Grid)({
  marginTop: "5rem",
  marginLeft: "1.6rem",
  fontSize: "3.5rem",
});

export const TopTitleBoxMobile = styled(Grid)({
  marginLeft: "2.0rem",
  fontSize: "3rem",
});

export const SubMainBox = styled(Grid)({
  margin: "2rem 0.5rem",
  marginLeft: "2.2rem",
});

export const CenterLayout = styled(Grid)({
  textAlign: "center",
});

export const RightLayout = styled("div")({
  textAlign: "right",
});

export const ImgCenterLayout = styled("div")({
  textAlign: "center",
  marginLeft: "5rem",
  verticalAlign: "center",
});

export const LeftMainItem = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
}));

// 메인 제목
export const MainTitle = styled(Grid)(({ theme }) => ({
  color: "#4B4F5A",
  fontWeight: "bold",
  fontSize: "18px",
}));

export const CursorPointer = styled("div")(({ theme }) => ({
  cursor: "pointer",
  zIndex: "10",
}));

export const TitleZIndex = styled("div")(({ theme }) => ({
  zIndex: "10",
}));

export const PageHeaderInfoBox = styled("div")(() => ({
  display: "flex",
  border: "1px solid #DCDFE3",
  borderRadius: "5px",
  alignItems: "center",
  height: "2.5rem",
  marginBottom: "10px",
  paddingLeft: "10px",
  color: GRAYPALETITLE,
}));

// Input Box
export const InputBox = styled("input")(({ theme }) => ({
  width: "100%",
  height: "2.4rem",
  border: "1px solid #DCDFE3",
  borderRadius: "8px",
  paddingLeft: "10px",
  "&::placeholder": { color: "#B2B4B8", fontWeight: "300" },
  "&:hover": { border: "1px solid #81848B" },
  "&:focus": { border: "1px solid #81848B", outline: "none" },
  "&:active": { border: "1px solid #81848B" },
}));

export const InputBoxNoBorder = styled("input")(({ theme }) => ({
  width: "100%",
  borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
  borderTop: "0px",
  borderLeft: "0px",
  borderRight: "0px",
  height: "33px",
  "&::placeholder": {
    color: "#B2B4B8",
    fontWeight: "300",
    textAlign: "center",
  },
  "&:hover": { borderBottom: "1px solid #81848B", outline: "none" },
  "&:focus": { borderBottom: "1px solid #81848B", outline: "none" },
  "&:active": { borderBottom: "1px solid #81848B", outline: "none" },
}));

export const InputLayout = styled(Grid)(() => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  width: "100%",
}));

export const InputBoxLeft = styled(Grid)(() => ({
  paddingBottom: "0.5rem",
}));

export const InputBoxRight = styled(Grid)(() => ({
  paddingLeft: "0.2rem",
  paddingBottom: "0.5rem",
}));

// 아이콘
export const IconColor = styled("div")(({ theme }) => ({
  color: "#4B4F5A",
}));

// 화면 크기 제한
export const MaxWidth = styled("div")(({ theme }) => ({
  maxWidth: "80rem",
  margin: "0rem auto",
}));

// 지도
export const MapLayout = styled("div")({
  margin: "0rem auto",
  height: "25rem",
  width: "100rem",
  minWidth: "98%",
  maxWidth: "100%",
});
export const MapButton = styled("div")(() => ({
  margin: "1rem auto",
  textAlign: "center",
}));

// raffle box
export const RaffleBox = styled("div")(() => ({
  border: "1px solid #EDEDED",
  borderRadius: "8px",
  padding: "1rem",
  marginBottom: "3rem",
  textAlign: "center",
}));

export const RaffleDay = styled("div")(() => ({
  textAlign: "center",
}));

export const RaffleContent = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

// 관리자 페이지
export const MoveTitle = styled("span")(() => ({
  color: GRAYNeutralCONTENTS,
  cursor: "pointer",
  fontSize: "1.2rem",
  marginRight: "0.5rem",
}));

export const AdminTabBox = styled("div")(() => ({
  marginTop: "1rem",
}));

export const AdminTabBox2 = styled("div")(() => ({
  marginTop: "3rem",
}));

export const ReservationDrawerTitle = styled(Grid)(({ theme }) => ({
  color: "#4B4F5A",
  fontWeight: "bold",
  padding: "1rem 0rem",
}));

// 표
export const StyledHead = styled(TableCell)(({ theme }) => ({
  fontFamily: "Noto sans CJK KR",
  backgroundColor: "#F0F2F5",
  borderTop: "1px solid #DCDFE3",
  borderBottom: "1px solid #DCDFE3",
  width: "75px",
  padding: "0.6rem",
  whiteSpace: "nowrap" as const,
  fontSize: "1rem",
  color: GRAYPALETITLE,
  fontWeight: "bold",
  height: "4rem",
}));
export const StyledHead1 = styled(TableCell)(({ theme }) => ({
  fontFamily: "Noto sans CJK KR",
  backgroundColor: "#F0F2F5",
  borderTop: "1px solid #DCDFE3",
  borderBottom: "1px solid #DCDFE3",
  width: "150px",
  padding: "0.6rem",
  whiteSpace: "nowrap" as const,
  fontSize: "1rem",
  color: GRAYPALETITLE,
  fontWeight: "bold",
  height: "4rem",
}));

export const StyledHead2 = styled(TableCell)(({ theme }) => ({
  fontFamily: "Noto sans CJK KR",
  backgroundColor: "#F0F2F5",
  borderTop: "1px solid #DCDFE3",
  borderBottom: "1px solid #DCDFE3",
  width: "300px",
  padding: "0.6rem",
  whiteSpace: "nowrap" as const,
  fontSize: "1rem",
  color: GRAYPALETITLE,
  fontWeight: "bold",
  height: "4rem",
}));

export const StyledHead3 = styled(TableCell)(({ theme }) => ({
  fontFamily: "Noto sans CJK KR",
  backgroundColor: "#F0F2F5",
  borderTop: "1px solid #DCDFE3",
  borderBottom: "1px solid #DCDFE3",
  width: "550px",
  padding: "0.6rem",
  whiteSpace: "nowrap" as const,
  fontSize: "1rem",
  color: GRAYPALETITLE,
  fontWeight: "bold",
  height: "4rem",
}));

export const StyledHead4 = styled(TableCell)(({ theme }) => ({
  fontFamily: "Noto sans CJK KR",
  backgroundColor: "#F0F2F5",
  borderTop: "1px solid #DCDFE3",
  borderBottom: "1px solid #DCDFE3",
  width: "230px",
  padding: "0.6rem",
  whiteSpace: "nowrap" as const,
  fontSize: "1rem",
  color: GRAYPALETITLE,
  fontWeight: "bold",
  height: "4rem",
}));

export const StyledHead5 = styled(TableCell)(({ theme }) => ({
  fontFamily: "Noto sans CJK KR",
  backgroundColor: "#F0F2F5",
  borderTop: "1px solid #DCDFE3",
  borderBottom: "1px solid #DCDFE3",
  width: "600px",
  padding: "0.6rem",
  whiteSpace: "nowrap" as const,
  fontSize: "1rem",
  color: GRAYPALETITLE,
  fontWeight: "bold",
  height: "4rem",
}));

export const StyledBody = styled(TableCell)(({ theme }) => ({
  fontFamily: "Noto sans CJK KR",
  borderTop: "1px solid #DCDFE3",
  borderBottom: "1px solid #DCDFE3",
  padding: "0.7rem",
  fontSize: "1rem",
  height: "4rem",
}));

export const StyledBody2 = styled(TableCell)(({ theme }) => ({
  fontFamily: "Noto sans CJK KR",
  borderTop: "1px solid #DCDFE3",
  borderBottom: "1px solid #DCDFE3",
  padding: "0.7rem",
  fontSize: "0.8rem",
  height: "4rem",
}));

export const StyledPointerBody = styled(TableCell)(({ theme }) => ({
  fontFamily: "Noto sans CJK KR",
  borderTop: "1px solid #DCDFE3",
  borderBottom: "1px solid #DCDFE3",
  padding: "0.7rem",
  fontSize: "1rem",
  height: "4rem",
  cursor: "pointer",
}));

export const StyledEditBody = styled(TableCell)(({ theme }) => ({
  fontFamily: "Noto sans CJK KR",
  borderTop: "1px solid #DCDFE3",
  borderBottom: "1px solid #DCDFE3",
  padding: "0.7rem",
  fontSize: "1rem",
  height: "4rem",
  cursor: "pointer",
  textDecoration: "underline #3D4861",
  textUnderlinePosition: "under",
}));

export const StyledBodyNoPadding = styled(TableCell)(({ theme }) => ({
  fontFamily: "Noto sans CJK KR",
  borderTop: "1px solid #DCDFE3",
  borderBottom: "1px solid #DCDFE3",
  padding: "0rem",
  fontSize: "1rem",
  height: "4rem",
}));

export const StyledEmptyBody = styled(Grid)(({ theme }) => ({
  fontFamily: "Noto sans CJK KR",
  borderBottom: "1px solid #DCDFE3",
  height: "50%",
  width: "100%",
  textAlign: "center",
  padding: "100px 0px",
}));

export const StyledEmptyBodyNoBorder = styled(Grid)(({ theme }) => ({
  fontFamily: "Noto sans CJK KR",
  height: "50%",
  width: "100%",
  textAlign: "center",
  padding: "100px 0px",
}));

// 카드형
export const CardDataLayout = styled(Grid)(() => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
}));

export const CardDataBox = styled("div")(() => ({
  border: "1px solid #DCDFE3",
  borderRadius: "8px",
  margin: "1rem",
  padding: "2rem 2rem",
  maxWidth: "20rem",
  width: "100%",
  height: "245px",
  overflow: "hidden",
  whiteSpace: "nowrap",
}));

export const CardReminderDataBox = styled("div")(() => ({
  border: "1px solid #DCDFE3",
  borderRadius: "8px",
  margin: "1rem",
  padding: "1.2rem 2rem",
  maxWidth: "20rem",
  width: "100%",
  height: "245px",
  overflow: "hidden",
  whiteSpace: "nowrap",
}));

export const AdminCardDataBox = styled("div")(() => ({
  border: "1px solid #DCDFE3",
  borderRadius: "8px",
  width: "350px",
  padding: "2rem 2rem",
  maxWidth: "90%",
  margin: "1rem",
  overflow: "hidden",
  whiteSpace: "nowrap",
}));

export const CardDataContent = styled("div")(() => ({
  marginBottom: "0.5rem",
}));

export const CardDataContent2 = styled("div")(() => ({
  margin: "0.5rem 0rem",
}));

export const CardDataPointerContent = styled("div")(() => ({
  margin: "0.5rem 0rem",
  cursor: "pointer",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}));

export const CardDataPointerEditContent = styled("div")(() => ({
  margin: "0.5rem 0rem",
  cursor: "pointer",
  textDecoration: "underline #3D4861",
  textUnderlinePosition: "under",
}));

export const CardDataCenterContent = styled("div")(() => ({
  marginTop: "1.0rem",
  justifyContent: "center",
  display: "flex",
  flexWrap: "wrap",
}));

export const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#e3165b",
    },
  },
});

// 나의 예약
export const BoardTopLayout = styled(Grid)({
  display: "flex",
  justifyContent: "spacebetween",
});

export const BoardDropdownLeft = styled(Grid)({
  display: "flex",
  justifyContent: "left",
});

export const BoardDropdownRight = styled(Grid)({
  display: "flex",
  justifyContent: "right",
  alignItems: "center",
});

export const DisabledItem = styled("div")({
  color: GRAYNeutralDISABLED,
  cursor: "pointer",
});

export const AppliedItem = styled("div")({
  cursor: "pointer",
});

export const ReservationTextarea = styled("textarea")({
  width: "100%",
  height: "32px",
  resize: "none",
  borderRadius: "8px",
  paddingLeft: "10px",
  "&::placeholder": { color: "#B2B4B8", fontWeight: "300" },
});

export const TypeGap = styled("div")({
  margin: "1rem 0rem",
  display: "flex",
  justifyContent: "space-between",
});

export const AxiosLoading = styled("div")({
  width: "100%",
  height: "100%",
  left: 0,
  top: 0,
  zIndex: 999,
  overflow: "auto",
  display: "block",
  position: "fixed",
  backgroundColor: "rgba(0, 0, 0, 0.3)",
});

export const AxiosLoadingIndicator = styled("div")({
  top: "45%",
  left: "calc(50% - 75px)",
  position: "fixed",
});

export const AccommodationDetailInfoBox = styled("div")(() => ({
  marginBottom: "2rem",
}));

export const AccommodationTitle = styled(Grid)(() => ({
  fontWeight: "700",
  marginBottom: "0.5rem",
}));

// 이미지

export const UploadImg = styled("img")(() => ({
  height: "80px",
  border: `1px solid ${GRAYPALEBORDER}`,
  borderRadius: "8px",
}));

export const ShowUploadImg = styled("img")(() => ({
  height: "80px",
  border: `1px solid ${GRAYPALEBORDER}`,
  borderRadius: "8px",
  marginRight: "5px",
  cursor: "pointer",
}));

export const ShowUploadFullImg = styled("img")(() => ({
  maxWidth: "100%",
}));

export const ImgLabelBox = styled("label")(() => ({
  cursor: "pointer",
  border: `1px solid ${GRAYPALEBORDER}`,
  borderRadius: "8px",
  height: "80px",
  width: "80px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "10px",
}));

export const DisabledImgBox = styled("input")(() => ({
  width: "0",
  height: "0",
  padding: "0",
  overflow: "hidden",
  border: "0",
}));

// textcolor
export const GrayPaleTitleText = styled("div")({
  color: GRAYPALETITLE,
  fontSize: "1rem",
});

export const GrayPaleContentsText = styled("div")({
  color: GRAYPALECONTENTS,
  fontSize: "1rem",
});

export const BlueText = styled("div")({
  color: BLUECONTENTS,
  fontWeight: "700",
  fontSize: "1.5rem",
});

export const OrangeText = styled("div")({
  color: ORANGECONTENTS,
  fontWeight: "700",
  fontSize: "1.5rem",
});

export const GreenContentsText = styled("div")({
  color: GREENTITLE,
  fontWeight: "700",
  fontSize: "1.5rem",
});

export const RedContentsText = styled("div")({
  color: REDCONTENTS,
  fontWeight: "700",
  fontSize: "1.5rem",
});

export const RedText = styled("div")({
  color: REDTITLE,
  fontWeight: "500",
});

export const GreenThickText = styled("div")({
  color: GREENACCENT,
  fontWeight: "700",
});

export const GreenText = styled("div")({
  color: GREENACCENT,
  fontWeight: "500",
});

export const BlackText = styled("span")({
  color: "#000000",
  fontWeight: "200",
});

export const ThickText = styled("div")({
  fontWeight: "600",
});

export const ThickTextSpan = styled("span")({
  fontWeight: "600",
});

// valid
export const ValidationText = styled("div")({
  fontSize: "0.8rem",
  color: REDTITLE,
  marginTop: "5px",
});

export const ValidationNoMarginText = styled("div")({
  fontSize: "0.8rem",
  color: REDTITLE,
});

export const ValidationMarginText = styled("div")({
  fontSize: "0.8rem",
  color: REDTITLE,
  marginLeft: "-8px",
});

export const ValidationNoMarginInfoText = styled("div")({
  fontSize: "0.8rem",
  color: ORANGETITLE,
  marginLeft: "-8px",
});

export const EditDropDownListComponentBox = styled(DropDownListComponent)(
  () => ({
    border: "0px",
    fontSize: "16px",
    fontFamily: "Noto sans CJK KR",
    "&::placeholder": {
      color: "#B2B4B8 !important",
      fontWeight: "300 !important",
      fontSize: "16px !important",
      fontFamily: "Noto sans CJK KR",
    },
  })
);

export const TextareaBox = styled("textarea")({
  width: "100%",
  height: "100px",
  resize: "none",
  borderRadius: "8px",
  paddingLeft: "10px",
  "&::placeholder": { color: "#B2B4B8", fontWeight: "300" },
});
