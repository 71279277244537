import {
  GreenThickText,
  ValidationMarginText,
  ValidationNoMarginInfoText,
} from "../../styles/theme";
import { useEffect, useState } from "react";
import _ from "lodash";
import { RecurrenceData, ReminderData } from "../../system/types/Reminder";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  RecurrenceDropdownComponent,
  RecurrenceDropdownList,
  ReminderComponetWidth,
  ReminderDayBox,
  ReminderModalContentNoMarginBox,
  ReminderModalDateTimeLeftBox,
  ReminderModalMainStartBox,
  ReminderModalRepeatBox,
  ReminderModalTitleMarginBox,
  ReminderModalTopMarginLayout,
  ReminderRepeatContentBox,
  ReminderRepeatFormBox,
  ReminderRepeatModalMainBox,
  ReminderRepeatModalMainCenterBox,
} from "../../styles/reminderStyle";
import {
  getDayOfTheWeekFormatter,
  isoDateFormatter,
} from "../Common/IsoDateFormatter";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import SelectedDay from "./SelectedDay";
import { InitRecurrence } from "../../system/types/initObject";
import RecurrenceText from "./RecurrenceText";
import { Tablet } from "../../pages/Layout/ResponsiveScreen";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { REDTITLE } from "../../styles/color";

const repeatDropDownList1 = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 16, 17, 18, 19, 20, 21, 22, 23,
  24, 25, 26, 27, 28, 29, 30, 31,
];

interface ReminderRepeatProps {
  data: ReminderData;
  setData: (status: ReminderData) => void;
  repeatData: RecurrenceData;
  setRepeatData: (status: RecurrenceData) => void;
}

function ReminderRepeat({
  data,
  setData,
  repeatData,
  setRepeatData,
}: ReminderRepeatProps) {
  const today = isoDateFormatter(new Date()) + "00:00:00";
  const [numberOfDayOfWeek, setNumberOfDayOfWeek] = useState("첫 번째");
  const [submitDayOfWeek, setSubmitDayOfWeek] = useState<string[]>([]);
  const [repeatPeriodStr, setRepeatPeriodStr] = useState("일"); // recurrencePatternType
  const dayOfWeekList = ["1월", "2화", "3수", "4목", "5금", "6토", "7일"];
  const [daysOfWeek, setDaysOfWeek] = useState<string[]>([
    "1월",
    "2화",
    "3수",
    "4목",
    "5금",
    "6토",
    "7일",
  ]);
  const engAllDaysOfWeek = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];

  // 월
  const [absoluteValue, setAbsoluteValue] = useState("absolute");

  const repeatMonthOnChange = (args: any) => {
    setRepeatData({ ...repeatData, interval: args.value });
  };

  // 년
  const onAbsoluteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAbsoluteValue((event.target as HTMLInputElement).value);
    let tmpDaysOfWeek = [];
    let now = new Date();
    let dayOfWeekNum = now.getDay() === 0 ? 6 : now.getDay() - 1;
    tmpDaysOfWeek.push(dayOfWeekList[dayOfWeekNum]);

    var preWeekIndex = "";
    switch (numberOfDayOfWeek) {
      case "첫 번째":
        preWeekIndex = "FIRST";
        break;
      case "두 번째":
        preWeekIndex = "SECOND";
        break;
      case "세 번째":
        preWeekIndex = "THIRD";
        break;
      case "네 번째":
        preWeekIndex = "FOURTH";
        break;
    }
    var preSubmitDayOfWeek: string[] = [];
    switch (new Date(data.startDate).getDay()) {
      case 0:
        setSubmitDayOfWeek(["SUNDAY"]);
        preSubmitDayOfWeek = ["SUNDAY"];
        break;
      case 1:
        setSubmitDayOfWeek(["MONDAY"]);
        preSubmitDayOfWeek = ["MONDAY"];
        break;
      case 2:
        setSubmitDayOfWeek(["TUESDAY"]);
        preSubmitDayOfWeek = ["TUESDAY"];
        break;
      case 3:
        setSubmitDayOfWeek(["WEDNESDAY"]);
        preSubmitDayOfWeek = ["WEDNESDAY"];
        break;
      case 4:
        setSubmitDayOfWeek(["THURSDAY"]);
        preSubmitDayOfWeek = ["THURSDAY"];
        break;
      case 5:
        setSubmitDayOfWeek(["FRIDAY"]);
        preSubmitDayOfWeek = ["FRIDAY"];
        break;
      case 6:
        setSubmitDayOfWeek(["SATURDAY"]);
        preSubmitDayOfWeek = ["SATURDAY"];
        break;
    }

    if ((event.target as HTMLInputElement).value === "absolute") {
      if (repeatPeriodStr === "월") {
        setRepeatData({
          ...InitRecurrence,
          interval: repeatData.interval,
          recurrenceEndDate: repeatData.recurrenceEndDate,
          recurrenceRangeType: repeatData.recurrenceRangeType,
          daysOfWeek: engAllDaysOfWeek,
          recurrencePatternType: "ABSOLUTE_MONTHLY",
          dayOfMonth: new Date(data.startDate).getDate(),
        });
      } else {
        setRepeatData({
          ...InitRecurrence,
          interval: repeatData.interval,
          recurrenceEndDate: repeatData.recurrenceEndDate,
          recurrenceRangeType: repeatData.recurrenceRangeType,
          daysOfWeek: engAllDaysOfWeek,
          recurrencePatternType: "ABSOLUTE_YEARLY",
          month: new Date(data.startDate).getMonth() + 1,
          dayOfMonth: new Date(data.startDate).getDate(),
        });
      }
    } else if ((event.target as HTMLInputElement).value === "relative") {
      if (repeatPeriodStr === "월") {
        setRepeatData({
          ...InitRecurrence,
          interval: repeatData.interval,
          recurrenceEndDate: repeatData.recurrenceEndDate,
          recurrenceRangeType: repeatData.recurrenceRangeType,
          daysOfWeek: preSubmitDayOfWeek,
          recurrencePatternType: "RELATIVE_MONTHLY",
          weekIndex: preWeekIndex,
        });
      } else {
        setRepeatData({
          ...InitRecurrence,
          interval: repeatData.interval,
          recurrenceEndDate: repeatData.recurrenceEndDate,
          recurrenceRangeType: repeatData.recurrenceRangeType,
          daysOfWeek: preSubmitDayOfWeek,
          recurrencePatternType: "RELATIVE_YEARLY",
          month: new Date(data.startDate).getMonth() + 1,
          weekIndex: preWeekIndex,
        });
      }
    } else {
      if (repeatPeriodStr === "월") {
        setRepeatData({
          ...InitRecurrence,
          interval: repeatData.interval,
          recurrenceEndDate: repeatData.recurrenceEndDate,
          recurrenceRangeType: repeatData.recurrenceRangeType,
          daysOfWeek: preSubmitDayOfWeek,
          recurrencePatternType: "RELATIVE_MONTHLY",
          weekIndex: "LAST",
        });
      } else {
        setRepeatData({
          ...InitRecurrence,
          interval: repeatData.interval,
          recurrenceEndDate: repeatData.recurrenceEndDate,
          recurrenceRangeType: repeatData.recurrenceRangeType,
          daysOfWeek: preSubmitDayOfWeek,
          recurrencePatternType: "RELATIVE_YEARLY",
          month: new Date(data.startDate).getMonth() + 1,
          weekIndex: "LAST",
        });
      }
    }
  };

  useEffect(() => {
    if (repeatPeriodStr === "주") {
      var preDaysOfWeek = [];
      for (var i = 0; i < daysOfWeek.length; i++) {
        if (daysOfWeek[i] === "1월") {
          preDaysOfWeek.push("MONDAY");
        } else if (daysOfWeek[i] === "2화") {
          preDaysOfWeek.push("TUESDAY");
        } else if (daysOfWeek[i] === "3수") {
          preDaysOfWeek.push("WEDNESDAY");
        } else if (daysOfWeek[i] === "4목") {
          preDaysOfWeek.push("THURSDAY");
        } else if (daysOfWeek[i] === "5금") {
          preDaysOfWeek.push("FRIDAY");
        } else if (daysOfWeek[i] === "6토") {
          preDaysOfWeek.push("SATURDAY");
        } else if (daysOfWeek[i] === "7일") {
          preDaysOfWeek.push("SUNDAY");
        }
      }
      setRepeatData({ ...repeatData, daysOfWeek: preDaysOfWeek });

      // "주"일 때 요일 선택에 따라 startDate 변경
      var preFirstDayOfWeeknumber = 0;
      const preFirstDayOfWeek =
        preDaysOfWeek[preDaysOfWeek.length - 1] === "SUNDAY"
          ? "SUNDAY"
          : preDaysOfWeek[0];
      if (preFirstDayOfWeek === "MONDAY") {
        preFirstDayOfWeeknumber = 1;
      } else if (preFirstDayOfWeek === "TUESDAY") {
        preFirstDayOfWeeknumber = 2;
      } else if (preFirstDayOfWeek === "WEDNESDAY") {
        preFirstDayOfWeeknumber = 3;
      } else if (preFirstDayOfWeek === "THURSDAY") {
        preFirstDayOfWeeknumber = 4;
      } else if (preFirstDayOfWeek === "FRIDAY") {
        preFirstDayOfWeeknumber = 5;
      } else if (preFirstDayOfWeek === "SATURDAY") {
        preFirstDayOfWeeknumber = 6;
      } else if (preFirstDayOfWeek === "SUNDAY") {
        preFirstDayOfWeeknumber = 0;
      }
      const preStartDateNumber = new Date(data.startDate).getDay();
      const preStartDate = new Date(data.startDate);
      const newStartDate = preStartDate.setDate(
        preStartDate.getDate() - preStartDateNumber + preFirstDayOfWeeknumber
      );
      const newEndDate = preStartDate.setDate(
        preStartDate.getDate() -
          preStartDateNumber +
          preFirstDayOfWeeknumber +
          1
      );
      setData({
        ...data,
        startDate: isoDateFormatter(new Date(newStartDate)) + " 00:00:00",
        endDate: isoDateFormatter(new Date(newEndDate)) + " 00:00:00",
      });
    }
  }, [daysOfWeek]);

  useEffect(() => {
    if (repeatData.id) {
      var preDaysOfWeek = [];
      for (var i = 0; i < repeatData.daysOfWeek.length; i++) {
        if (repeatData.daysOfWeek[i] === "MONDAY") {
          preDaysOfWeek.push("1월");
        } else if (repeatData.daysOfWeek[i] === "TUESDAY") {
          preDaysOfWeek.push("2화");
        } else if (repeatData.daysOfWeek[i] === "WEDNESDAY") {
          preDaysOfWeek.push("3수");
        } else if (repeatData.daysOfWeek[i] === "THURSDAY") {
          preDaysOfWeek.push("4목");
        } else if (repeatData.daysOfWeek[i] === "FRIDAY") {
          preDaysOfWeek.push("5금");
        } else if (repeatData.daysOfWeek[i] === "SATURDAY") {
          preDaysOfWeek.push("6토");
        } else if (repeatData.daysOfWeek[i] === "SUNDAY") {
          preDaysOfWeek.push("7일");
        }
      }

      switch (repeatData.recurrencePatternType) {
        case "DAILY":
          setRepeatPeriodStr("일");
          break;
        case "WEEKLY":
          setRepeatPeriodStr("주");
          setDaysOfWeek(preDaysOfWeek);
          break;
        case "ABSOLUTE_MONTHLY":
          setAbsoluteValue("absolute");
          setRepeatPeriodStr("월");
          break;
        case "RELATIVE_MONTHLY":
          setAbsoluteValue("relative");
          setRepeatPeriodStr("월");

          break;
        case "ABSOLUTE_YEARLY":
          setAbsoluteValue("absolute");
          setRepeatPeriodStr("년");
          break;
        case "RELATIVE_YEARLY":
          setAbsoluteValue("relative");
          setRepeatPeriodStr("년");
          break;
      }
    }
  }, [repeatData.id]);

  // 일, 주, 월, 년 선택 (모든 값 리셋)
  const repeatPeriodStrOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRepeatPeriodStr((event.target as HTMLInputElement).value);
    let tmpDaysOfWeek = [];
    let now = new Date();
    let dayOfWeekNum = now.getDay() === 0 ? 6 : now.getDay() - 1;
    tmpDaysOfWeek.push(dayOfWeekList[dayOfWeekNum]);
    var preWeekIndex = "";
    switch (numberOfDayOfWeek) {
      case "첫 번째":
        preWeekIndex = "FIRST";
        break;
      case "두 번째":
        preWeekIndex = "SECOND";
        break;
      case "세 번째":
        preWeekIndex = "THIRD";
        break;
      case "네 번째":
        preWeekIndex = "FOURTH";
        break;
    }
    var preSubmitDayOfWeek: string[] = [];
    switch (new Date(data.startDate).getDay()) {
      case 0:
        setSubmitDayOfWeek(["SUNDAY"]);
        preSubmitDayOfWeek = ["SUNDAY"];
        break;
      case 1:
        setSubmitDayOfWeek(["MONDAY"]);
        preSubmitDayOfWeek = ["MONDAY"];
        break;
      case 2:
        setSubmitDayOfWeek(["TUESDAY"]);
        preSubmitDayOfWeek = ["TUESDAY"];
        break;
      case 3:
        setSubmitDayOfWeek(["WEDNESDAY"]);
        preSubmitDayOfWeek = ["WEDNESDAY"];
        break;
      case 4:
        setSubmitDayOfWeek(["THURSDAY"]);
        preSubmitDayOfWeek = ["THURSDAY"];
        break;
      case 5:
        setSubmitDayOfWeek(["FRIDAY"]);
        preSubmitDayOfWeek = ["FRIDAY"];
        break;
      case 6:
        setSubmitDayOfWeek(["SATURDAY"]);
        preSubmitDayOfWeek = ["SATURDAY"];
        break;
    }
    switch ((event.target as HTMLInputElement).value) {
      case "일":
        setRepeatData({
          ...InitRecurrence,
          interval: repeatData.interval,
          recurrenceEndDate: repeatData.recurrenceEndDate,
          recurrenceRangeType: repeatData.recurrenceRangeType,
          daysOfWeek: engAllDaysOfWeek,
          recurrencePatternType: "DAILY",
        });
        break;
      case "주":
        setDaysOfWeek(tmpDaysOfWeek);
        setRepeatData({
          ...InitRecurrence,
          interval: repeatData.interval,
          recurrenceEndDate: repeatData.recurrenceEndDate,
          recurrenceRangeType: repeatData.recurrenceRangeType,
          daysOfWeek: tmpDaysOfWeek,
          recurrencePatternType: "WEEKLY",
        });
        break;
      case "월":
        if (absoluteValue === "absolute") {
          setRepeatData({
            ...InitRecurrence,
            interval: repeatData.interval,
            recurrenceEndDate: repeatData.recurrenceEndDate,
            recurrenceRangeType: repeatData.recurrenceRangeType,
            daysOfWeek: engAllDaysOfWeek,
            recurrencePatternType: "ABSOLUTE_MONTHLY",
            dayOfMonth: new Date(data.startDate).getDate(),
          });
        } else {
          setRepeatData({
            ...InitRecurrence,
            interval: repeatData.interval,
            recurrenceEndDate: repeatData.recurrenceEndDate,
            recurrenceRangeType: repeatData.recurrenceRangeType,
            daysOfWeek: preSubmitDayOfWeek,
            recurrencePatternType: "RELATIVE_MONTHLY",
            weekIndex: preWeekIndex,
          });
        }

        break;
      case "년":
        if (absoluteValue === "absolute") {
          setRepeatData({
            ...InitRecurrence,
            interval: repeatData.interval,
            recurrenceEndDate: repeatData.recurrenceEndDate,
            recurrenceRangeType: repeatData.recurrenceRangeType,
            daysOfWeek: engAllDaysOfWeek,
            recurrencePatternType: "ABSOLUTE_YEARLY",
            month: new Date(data.startDate).getMonth() + 1,
            dayOfMonth: new Date(data.startDate).getDate(),
          });
        } else {
          setRepeatData({
            ...InitRecurrence,
            interval: repeatData.interval,
            recurrenceEndDate: repeatData.recurrenceEndDate,
            recurrenceRangeType: repeatData.recurrenceRangeType,
            daysOfWeek: preSubmitDayOfWeek,
            recurrencePatternType: "RELATIVE_YEARLY",
            month: new Date(data.startDate).getMonth() + 1,
            weekIndex: preWeekIndex,
          });
        }
        break;
    }
  };

  // 시작, 종료날짜
  const onChangeStartDate = (args: any) => {
    const startDate = new Date(args.target.value);
    const preEndDate = new Date(args.target.value);
    const endDate = preEndDate.setDate(preEndDate.getDate() + 1);
    setData({
      ...data,
      [(args.target as HTMLInputElement).name]:
        isoDateFormatter(args.target.value) + " 00:00:00",
      endDate: isoDateFormatter(new Date(endDate)) + " 00:00:00",
    });
    if (repeatPeriodStr === "월" || repeatPeriodStr === "년") {
      var preSubmitDayOfWeek: string[] = [];
      switch (new Date(startDate).getDay()) {
        case 0:
          setSubmitDayOfWeek(["SUNDAY"]);
          preSubmitDayOfWeek = ["SUNDAY"];
          break;
        case 1:
          setSubmitDayOfWeek(["MONDAY"]);
          preSubmitDayOfWeek = ["MONDAY"];
          break;
        case 2:
          setSubmitDayOfWeek(["TUESDAY"]);
          preSubmitDayOfWeek = ["TUESDAY"];
          break;
        case 3:
          setSubmitDayOfWeek(["WEDNESDAY"]);
          preSubmitDayOfWeek = ["WEDNESDAY"];
          break;
        case 4:
          setSubmitDayOfWeek(["THURSDAY"]);
          preSubmitDayOfWeek = ["THURSDAY"];
          break;
        case 5:
          setSubmitDayOfWeek(["FRIDAY"]);
          preSubmitDayOfWeek = ["FRIDAY"];
          break;
        case 6:
          setSubmitDayOfWeek(["SATURDAY"]);
          preSubmitDayOfWeek = ["SATURDAY"];
          break;
      }
      const preDate = new Date(startDate).getDate();
      const preNumberOfDayOfWeek = Math.floor((preDate - 1) / 7);
      var preWeekIndex = "";
      switch (preNumberOfDayOfWeek) {
        case 0:
          setNumberOfDayOfWeek("첫 번째");
          preWeekIndex = "FIRST";
          break;
        case 1:
          setNumberOfDayOfWeek("두 번째");
          preWeekIndex = "SECOND";
          break;
        case 2:
          setNumberOfDayOfWeek("세 번째");
          preWeekIndex = "THIRD";
          break;
        case 3:
          setNumberOfDayOfWeek("네 번째");
          preWeekIndex = "FOURTH";
          break;
      }

      if (absoluteValue === "absolute") {
        if (repeatPeriodStr === "월") {
          setRepeatData({
            ...InitRecurrence,
            interval: repeatData.interval,
            recurrenceEndDate: repeatData.recurrenceEndDate,
            recurrenceRangeType: repeatData.recurrenceRangeType,
            daysOfWeek: engAllDaysOfWeek,
            recurrencePatternType: "ABSOLUTE_MONTHLY",
            dayOfMonth: new Date(startDate).getDate(),
          });
        } else {
          setRepeatData({
            ...InitRecurrence,
            interval: repeatData.interval,
            recurrenceEndDate: repeatData.recurrenceEndDate,
            recurrenceRangeType: repeatData.recurrenceRangeType,
            daysOfWeek: engAllDaysOfWeek,
            recurrencePatternType: "ABSOLUTE_YEARLY",
            month: new Date(data.startDate).getMonth() + 1,
            dayOfMonth: new Date(startDate).getDate(),
          });
        }
      } else if (absoluteValue === "relative") {
        if (repeatPeriodStr === "월") {
          setRepeatData({
            ...InitRecurrence,
            interval: repeatData.interval,
            recurrenceEndDate: repeatData.recurrenceEndDate,
            recurrenceRangeType: repeatData.recurrenceRangeType,
            daysOfWeek: preSubmitDayOfWeek,
            recurrencePatternType: "RELATIVE_MONTHLY",
            weekIndex: preWeekIndex,
          });
        } else {
          setRepeatData({
            ...InitRecurrence,
            interval: repeatData.interval,
            recurrenceEndDate: repeatData.recurrenceEndDate,
            recurrenceRangeType: repeatData.recurrenceRangeType,
            daysOfWeek: preSubmitDayOfWeek,
            recurrencePatternType: "RELATIVE_YEARLY",
            month: new Date(startDate).getMonth() + 1,
            weekIndex: preWeekIndex,
          });
        }
      }
    }
  };

  const onChangeRecurrenceData = (args: any) => {
    setRepeatData({
      ...repeatData,
      recurrenceEndDate: isoDateFormatter(args.target.value),
      recurrenceRangeType: "END_DATE",
    });
  };

  useEffect(() => {
    if (repeatData.recurrenceEndDate === undefined) {
      setRepeatData({ ...repeatData, recurrenceRangeType: "NO_END" });
    }
  }, [repeatData.recurrenceEndDate]);

  return (
    <ReminderModalMainStartBox>
      <Tablet>
        <ReminderModalTitleMarginBox>주기 설정</ReminderModalTitleMarginBox>
      </Tablet>
      <ReminderModalContentNoMarginBox>
        <ReminderModalRepeatBox>
          <ReminderRepeatModalMainBox>
            <GreenThickText>
              <RecurrenceText
                data={data}
                repeatData={repeatData}
                type="all"
                submitDayOfWeek={submitDayOfWeek}
              />
            </GreenThickText>
          </ReminderRepeatModalMainBox>

          {/* 반복 주기 */}
          <ReminderRepeatModalMainCenterBox>
            <ReminderModalDateTimeLeftBox>
              반복 주기가
            </ReminderModalDateTimeLeftBox>

            <ReminderRepeatFormBox>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={repeatPeriodStr}
                onChange={repeatPeriodStrOnChange}
              >
                <FormControlLabel value="일" control={<Radio />} label="일" />
                <FormControlLabel value="주" control={<Radio />} label="주" />
                <FormControlLabel value="월" control={<Radio />} label="월" />
                <FormControlLabel value="년" control={<Radio />} label="년" />
              </RadioGroup>
            </ReminderRepeatFormBox>

            <div> 일 때,</div>
          </ReminderRepeatModalMainCenterBox>
          {/* 반복 설정 */}
          <ReminderRepeatModalMainBox>
            <RecurrenceDropdownList>
              <ReminderComponetWidth>
                <DatePickerComponent
                  min={new Date()}
                  name="startDate"
                  onChange={onChangeStartDate}
                  format="yyyy-MM-dd"
                  placeholder="알림 시작 날짜"
                  value={new Date(data.startDate)}
                  showClearButton={false}
                  locale="ko"
                />
              </ReminderComponetWidth>{" "}
              <ReminderRepeatContentBox>부터</ReminderRepeatContentBox>
              <RecurrenceDropdownComponent>
                <DropDownListComponent
                  dataSource={repeatDropDownList1}
                  change={(args) => repeatMonthOnChange(args)}
                  value={repeatData.interval || 1}
                  placeholder=""
                  popupHeight="200px"
                />
              </RecurrenceDropdownComponent>
              {repeatPeriodStr === "일" && (
                <ReminderRepeatContentBox>일 마다</ReminderRepeatContentBox>
              )}
              {repeatPeriodStr === "주" && (
                <ReminderRepeatContentBox>주 마다</ReminderRepeatContentBox>
              )}
              {repeatPeriodStr === "월" && (
                <ReminderRepeatContentBox>개월 마다</ReminderRepeatContentBox>
              )}
              {repeatPeriodStr === "년" && (
                <ReminderRepeatContentBox>년 마다</ReminderRepeatContentBox>
              )}
              <ReminderComponetWidth>
                <DatePickerComponent
                  name="recurrenceEndDate"
                  onChange={onChangeRecurrenceData}
                  format="yyyy-MM-dd"
                  placeholder="최종 종료 날짜"
                  min={new Date(data.endDate)}
                  style={
                    repeatData.recurrenceEndDate &&
                    new Date(data.startDate) >
                      new Date(repeatData.recurrenceEndDate)
                      ? { color: REDTITLE }
                      : {}
                  }
                  value={
                    repeatData.recurrenceEndDate
                      ? new Date(repeatData.recurrenceEndDate)
                      : undefined
                  }
                  locale="ko"
                />
              </ReminderComponetWidth>
              {repeatData.recurrenceEndDate ? (
                <ReminderRepeatContentBox>까지</ReminderRepeatContentBox>
              ) : (
                <ReminderRepeatContentBox>
                  종료 기한 없이
                </ReminderRepeatContentBox>
              )}
              {repeatPeriodStr === "일" ? <div>지속됩니다.</div> : null}
            </RecurrenceDropdownList>
          </ReminderRepeatModalMainBox>

          <ReminderRepeatModalMainBox>
            <ReminderRepeatContentBox>
              {repeatPeriodStr === "일" && (
                <ReminderModalTopMarginLayout></ReminderModalTopMarginLayout>
              )}
              {repeatPeriodStr === "주" && (
                <ReminderDayBox>
                  <SelectedDay
                    daysOfWeek={daysOfWeek}
                    setDaysOfWeek={setDaysOfWeek}
                    recurrenceType="주"
                    numberOfWeek={repeatData.interval}
                    repeatData={repeatData}
                    setRepeatData={setRepeatData}
                    setRepeatPeriodStr={setRepeatPeriodStr}
                  />{" "}
                  <div> 요일에 반복됩니다.</div>
                </ReminderDayBox>
              )}
              {repeatPeriodStr === "월" && (
                <ReminderRepeatFormBox>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={absoluteValue}
                      onChange={onAbsoluteChange}
                    >
                      <FormControlLabel
                        value="absolute"
                        control={<Radio />}
                        label={
                          new Date(data.startDate).getDate() +
                          "일에 반복합니다."
                        }
                      />
                      <FormControlLabel
                        value="relative"
                        control={<Radio />}
                        label={
                          numberOfDayOfWeek +
                          " " +
                          getDayOfTheWeekFormatter(data.startDate) +
                          "에 반복합니다."
                        }
                      />
                      {numberOfDayOfWeek === "네 번째" ? (
                        <FormControlLabel
                          value="LAST"
                          control={<Radio />}
                          label={
                            "마지막 주 " +
                            getDayOfTheWeekFormatter(data.startDate) +
                            "에 반복합니다."
                          }
                        />
                      ) : null}
                    </RadioGroup>
                  </FormControl>
                </ReminderRepeatFormBox>
              )}
              {repeatPeriodStr === "년" && (
                <div>
                  <ReminderRepeatFormBox>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={absoluteValue}
                        onChange={onAbsoluteChange}
                      >
                        <FormControlLabel
                          value="absolute"
                          control={<Radio />}
                          label={
                            new Date(data.startDate).getMonth() +
                            1 +
                            "월 " +
                            new Date(data.startDate).getDate() +
                            "일에 반복합니다."
                          }
                        />
                        <FormControlLabel
                          value="relative"
                          control={<Radio />}
                          label={
                            new Date(data.startDate).getMonth() +
                            1 +
                            "월 " +
                            numberOfDayOfWeek +
                            " " +
                            getDayOfTheWeekFormatter(data.startDate) +
                            "에 반복합니다."
                          }
                        />
                        {numberOfDayOfWeek === "네 번째" ? (
                          <FormControlLabel
                            value="LAST"
                            control={<Radio />}
                            label={
                              "마지막 주 " +
                              getDayOfTheWeekFormatter(data.startDate) +
                              "에 반복합니다."
                            }
                          />
                        ) : null}
                      </RadioGroup>
                    </FormControl>
                  </ReminderRepeatFormBox>
                </div>
              )}
              <ValidationNoMarginInfoText>
                <ErrorOutlineIcon fontSize="small" /> 최종 종료 날짜를 설정하지
                않으시면 리마인더가 종료 기한 없이 계속 생성됩니다.
              </ValidationNoMarginInfoText>
              {repeatData.recurrenceEndDate &&
                new Date(data.startDate) >
                  new Date(repeatData.recurrenceEndDate) && (
                  <ValidationMarginText>
                    <ErrorOutlineIcon fontSize="small" /> 최종 종료 날짜를
                    리마인더 시작 날짜 이후로 변경해주세요.
                  </ValidationMarginText>
                )}
            </ReminderRepeatContentBox>
          </ReminderRepeatModalMainBox>
        </ReminderModalRepeatBox>
      </ReminderModalContentNoMarginBox>
    </ReminderModalMainStartBox>
  );
}
export default ReminderRepeat;
