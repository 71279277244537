import { Grid } from "@mui/material";
import {
  AdminTabBox,
  InputBox,
  ReservationDrawerTitle,
  InputBoxLeft,
  InputBoxRight,
  InputLayout,
  RightLayout,
  CursorPointer,
  LightTooltip,
} from "../../../styles/theme";
import { Fragment, createRef, useEffect, useState } from "react";
import SelectAccommodationBox from "../../Common/SelectAccommodationBox";
import { ColorPalette } from "../../../system/Constants";
import { CustomButtonGreen, CustomButtonRed } from "../../../styles/button";
import {
  Bridge,
  Buildings,
  Garage,
  House,
  HouseLine,
  Lighthouse,
  MinusCircle,
  Mountains,
  Park,
  Plus,
  PlusCircle,
  SwimmingPool,
  Tent,
  Tipi,
  Warehouse,
} from "@phosphor-icons/react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { InitDetailedAccommodation } from "../../../system/types/initObject";
import {
  AmenitiesDetail,
  DetailedAccommodation,
  DetailedAddresses,
} from "../../../system/types/Accommodation";
import { ResourceApi } from "../../../system/api/Resource";
import { ErrorHandler } from "../../../system/ApiService";
import { AccommodationApi } from "../../../system/api/AccommodationApi";
import SelectEquippedItems from "../../Common/SelectEquippedItems";
import { useRecoilState } from "recoil";
import { accommodationState } from "../../../system/atoms";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import AlertModal from "../../Common/AlertModal";
import CrossEditor from "../../Common/Editor/CrossEditor";
import Swal from "sweetalert2";
import { GREENCONTENTS, REDTITLE } from "../../../styles/color";
import InfoIcon from "@mui/icons-material/Info";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  AccommodationBox,
  AccommodationIcon,
  SelectedIcon,
  AccommodationInfoBox,
  AccommodationInfoTitle,
  ColorBox,
  ColorBigBox,
  ColorExample,
  DongHoPlusIcon,
  AccommodationInfoIcon,
} from "../../../styles/accommodationStyle";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { isoDateFormatter } from "../../Common/IsoDateFormatter";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";

function validation(data: DetailedAccommodation) {
  if (data.name === "") {
    AlertModal("check", "휴양소 이름을 작성해주세요.");
    return false;
  } else if (data.type === "") {
    AlertModal("check", "휴양소 종류를 작성해주세요.");
    return false;
  } else if (data.address === "") {
    AlertModal("check", "휴양소 주소를 작성해주세요.");
    return false;
  } else if (data.icon === "") {
    AlertModal("check", "휴양소 아이콘을 작성해주세요.");
    return false;
  } else if (data.color === "") {
    AlertModal("check", "휴양소 조회 시 보여질 색상을 선택해주세요.");
    return false;
  }
  return true;
}

function AccommodationInfo() {
  const [color, setColor] = useState("");
  const Loading = useLoadingDispatch();
  const [data, setData] = useState<DetailedAccommodation>(
    InitDetailedAccommodation
  );
  const [resourceData, setResourceData] = useRecoilState(accommodationState);
  const [resourceId, setResourceId] = useState(0);
  const refContent = createRef<CrossEditor>();
  const [addInfo, setAddInfo] = useState(false);
  const params = {
    Width: "100%",
    Height: 800,
  };
  const typeList = ["아파트", "리조트", "캠핑장"];
  var submitcheck = false;
  const selectAccomodation = (id: number) => {
    setResourceId(id);
    setDonghoList([{ dong: 0, ho: 0 }]);
    setData({ ...InitDetailedAccommodation, setInfo: false });
  };

  const changeColor = (item: string) => {
    setColor(item);
    setData({
      ...data,
      color: item,
    });
  };

  useEffect(() => {
    refreshResource();
  }, []);

  function refreshResource() {
    Loading({ type: "LOADING" });
    ResourceApi.getResource("휴양소")
      .then((res) => {
        setResourceData(res.data);
        setResourceId(res.data.id);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      })
      .finally(() => {
        Loading({ type: "COMPLETE" });
      });
  }

  useEffect(() => {
    if (resourceId) {
      Loading({ type: "LOADING" });
      AccommodationApi.getAccommodationDetail(resourceId)
        .then((res) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(res.data.content, "text/html");
          const tableElement = doc.querySelector(
            'table[name="namo-editor-table-class"]'
          );

     
        const modifiedDonghoList = res.data.detailedAddresses.map((item:any) => {
          return {
            ...item,
            availableCheck: !!(item.forbidStartDate || item.forbidEndDate)
          };
        });


          if (tableElement) {
            setData({ ...res.data, setInfo: true });
            setDonghoList(modifiedDonghoList);
            setColor(res.data.color);
          } else {
            setData({
              ...res.data,
              desc1: res.data.desc1,
              setInfo: true,
            });
            setDonghoList(modifiedDonghoList);
            setColor(res.data.color);
          }
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        })
        .finally(() => {
          Loading({ type: "COMPLETE" });
        });
    } else {
      setResourceId(0);
      setData({ ...data, setInfo: true });
      setDonghoList([{ dong: 0, ho: 0 }]);
      setAddInfo(true);
    }
  }, [resourceId]);

  const MoveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [donghoList, setDonghoList]: any = useState([
    { dong: 0, ho: 0, frontDoorPassword: "", roomPassword: "" },
  ]);
  const onAddDongHo = () => {
    let donghoArr = [...donghoList];
    let counter = donghoArr.length;
    counter += 1;
    donghoArr.push({ dong: 0, ho: 0, frontDoorPassword: "", roomPassword: "" });
    setDonghoList(donghoArr);
  };

  const onRemoveDongHo = () => {
    let donghoArr = [...donghoList];
    let counter = donghoArr.slice(0, -1);
    setDonghoList(counter);
  };
  const onChange = (args: any) => {
    setData({
      ...data,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };

  const onChangeTest = (e: any, index: number, type: string) => {
    let preDonghoList = [...donghoList];
    if (type === "dong") {
      preDonghoList[index].dong = e.target.value;
    }
    if (type === "ho") {
      preDonghoList[index].ho = e.target.value;
    }
    if (type === "frontDoorPassword") {
      preDonghoList[index].frontDoorPassword = e.target.value;
    }
    if (type === "roomPassword") {
      preDonghoList[index].roomPassword = e.target.value;
    }
    if (type === "message") {
      preDonghoList[index].message = e.target.value;
    }
    if (type === "datepicker") {
      if (e.value && e.value[0] && e.value[1]) {
        preDonghoList[index].forbidStartDate = isoDateFormatter(
          new Date(e.value[0])
        );
        preDonghoList[index].forbidEndDate = isoDateFormatter(
          new Date(e.value[1])
        );
      }
    }
    if (type === "disabledCheckbox") {
      if (preDonghoList[index].availableCheck === true) {
        preDonghoList[index].forbidStartDate = null;
        preDonghoList[index].forbidEndDate = null;
        preDonghoList[index].message = null;
      }
      preDonghoList[index].availableCheck =
        !preDonghoList[index].availableCheck;
    }
    setDonghoList(preDonghoList);
  };
  const onChangeEquippedItems = (itemList: AmenitiesDetail[]) => {
    if (itemList) {
      setData({ ...data, amenities: itemList });
    }
  };

  const onRemoveAccommodation = () => {
    Swal.fire({
      title: "정말 삭제하시겠습니까?",
      text: "선택하신 휴양소가 삭제됩니다.",
      icon: "question",
      confirmButtonText: "예",
      cancelButtonText: "아니오",
      showCancelButton: true,
      confirmButtonColor: GREENCONTENTS,
      cancelButtonColor: REDTITLE,
    }).then((result) => {
      if (result.isConfirmed && data.id) {
        AccommodationApi.deleteAccommodation(data.id)
          .then(() => {
            setData(InitDetailedAccommodation);
            refreshResource();
            setResourceId(0);
            MoveToTop();
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("err", msg, "휴양소 삭제 중 오류가 발생했습니다.");
          });
      }
    });
  };

  const onSave = () => {
    if (submitcheck === false && resourceId && refContent.current) {
      const editor = refContent.current.editorRef;

      submitcheck = true;
      if (validation(data)) {
        Loading({ type: "LOADING" });
        AccommodationApi.updateAccommodation({
          ...data,
          id: resourceId,
          detailedAddresses: donghoList,
          desc1: editor.GetBodyValue() || "",
        })
          .then(() => {
            MoveToTop();
            AlertModal("success", "휴양소가 수정되었습니다.");
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          })
          .finally(() => {
            Loading({ type: "COMPLETE" });
            submitcheck = false;
          });
      }
    } else if (submitcheck === false && refContent.current) {
      const editor = refContent.current.editorRef;

      submitcheck = true;
      if (validation(data)) {
        Loading({ type: "LOADING" });
        AccommodationApi.createAccommodation({
          ...data,
          detailedAddresses: donghoList,
          desc1: editor.GetBodyValue() || "",
        })
          .then((res) => {
            setData(InitDetailedAccommodation);
            AlertModal("success", "휴양소가 생성되었습니다.");
            MoveToTop();
            setResourceId(res.data.id);
            refreshResource();
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          })
          .finally(() => {
            Loading({ type: "COMPLETE" });
            submitcheck = false;
          });
      }
    }
  };

  let _editorStarted = false;

  const onLoaded = (_: any, editor: { GetBodyValue: () => any }) => {
    _editorStarted = true;
  };

  return (
    <AdminTabBox>
      <Grid item xs={12}>
        <AccommodationBox>
          <CursorPointer onClick={() => selectAccomodation(0)}>
            {resourceId === 0 ? (
              <SelectedIcon>
                <Plus size={40} />

                <div>추가</div>
              </SelectedIcon>
            ) : (
              <AccommodationIcon>
                <Plus size={40} />

                <div>추가</div>
              </AccommodationIcon>
            )}
          </CursorPointer>
          <SelectAccommodationBox
            data={resourceData}
            resourceId={resourceId}
            selectAccomodation={selectAccomodation}
          />
        </AccommodationBox>
      </Grid>

      <Grid item xs={12}>
        <AccommodationInfoBox item xs={12}>
          <ReservationDrawerTitle> 휴양소 정보</ReservationDrawerTitle>

          <InputLayout item xs={12}>
            <InputBoxLeft item xs={6}>
              <AccommodationInfoTitle>이름</AccommodationInfoTitle>
              <InputBox
                type="text"
                onChange={onChange}
                name="name"
                value={data.name || ""}
                placeholder="상단에 아이콘과 함께 보여질 이름을 작성해주세요. ex. 고성, 양평, 여수, 태안"
              />
            </InputBoxLeft>

            <InputBoxRight item xs={6}>
              <AccommodationInfoTitle>종류</AccommodationInfoTitle>
              <DropDownListComponent
                name="type"
                dataSource={typeList}
                value={data.type || ""}
                onChange={onChange}
                placeholder="휴양소 타입을 선택해주세요."
                delayUpdate={true}
              />
            </InputBoxRight>
            <Grid item xs={12}>
              <AccommodationInfoTitle>주소</AccommodationInfoTitle>
              <InputBox
                type="text"
                name="address"
                value={data.address || ""}
                onChange={onChange}
                placeholder="휴양소의 주소를 작성해주세요. ex. 서울특별시 강남구 언주로 625"
              />
            </Grid>
            <InputBoxLeft item xs={6}>
              <AccommodationInfoTitle>
                위도
                <AccommodationInfoIcon>
                  <LightTooltip
                    title={
                      <Fragment>
                        <div>
                          - 구글지도 화면 오른쪽 아래 보이는 나라를 타 국가로
                          바꿔준 후 휴양소 조회 후 클릭{" "}
                        </div>
                        <div>
                          - 구글지도에서 마우스 우클릭 후 여기를 출발지로 설정
                          선택. URL 창에서 위도와 경도 추출.{" "}
                        </div>
                        <a href="https://www.google.com/maps/">
                          구글지도로 이동
                        </a>
                      </Fragment>
                    }
                    placement="right"
                  >
                    <InfoIcon />
                  </LightTooltip>
                </AccommodationInfoIcon>
              </AccommodationInfoTitle>
              <InputBox
                type="text"
                onChange={onChange}
                name="latitude"
                value={data.latitude || ""}
                placeholder="휴양소의 위도를 입력해주세요."
              />
            </InputBoxLeft>

            <InputBoxRight item xs={6} mt="3px">
              <AccommodationInfoTitle>경도</AccommodationInfoTitle>
              <InputBox
                type="text"
                onChange={onChange}
                value={data.longitude || ""}
                name="longitude"
                placeholder="휴양소의 경도를 입력해주세요."
              />
            </InputBoxRight>
          </InputLayout>
        </AccommodationInfoBox>
      </Grid>
      <Grid item xs={12}>
        <AccommodationInfoBox item xs={12}>
          <ReservationDrawerTitle> 상세 주소</ReservationDrawerTitle>

          <InputLayout item xs={12}>
            <InputBoxLeft item xs={1.5}>
              <AccommodationInfoTitle>동</AccommodationInfoTitle>
            </InputBoxLeft>

            <InputBoxRight item xs={1.5}>
              <AccommodationInfoTitle>호수</AccommodationInfoTitle>
            </InputBoxRight>
            <InputBoxLeft item xs={1.5}>
              <AccommodationInfoTitle>출입문 비밀번호</AccommodationInfoTitle>
            </InputBoxLeft>

            <InputBoxRight item xs={1.5}>
              <AccommodationInfoTitle>방 비밀번호</AccommodationInfoTitle>
            </InputBoxRight>
            <InputBoxRight item xs={6}>
              <AccommodationInfoTitle>비활성화</AccommodationInfoTitle>
            </InputBoxRight>
            {donghoList &&
              donghoList.map((item: DetailedAddresses, index: number) => (
                <InputLayout
                  container
                  item
                  xs={12}
                  key={index}
                  alignItems="center"
                >
                  <InputBoxLeft item xs={1.5}>
                    <InputBox
                      type="text"
                      name="dong"
                      value={item.dong || ""}
                      placeholder="숫자 작성. ex.101"
                      onChange={(e) => {
                        onChangeTest(e, index, "dong");
                      }}
                    />
                  </InputBoxLeft>

                  <InputBoxRight item xs={1.5}>
                    <InputBox
                      type="text"
                      name="ho"
                      value={item.ho || ""}
                      placeholder="숫자 작성. ex.102"
                      onChange={(e: any) => {
                        onChangeTest(e, index, "ho");
                      }}
                    />
                  </InputBoxRight>
                  <InputBoxRight item xs={1.5}>
                    <InputBox
                      type="text"
                      name="frontDoorPassword"
                      value={item.frontDoorPassword || ""}
                      placeholder="출입문 비밀번호 입력."
                      onChange={(e) => {
                        onChangeTest(e, index, "frontDoorPassword");
                      }}
                    />
                  </InputBoxRight>
                  <InputBoxRight item xs={1.5}>
                    <InputBox
                      type="text"
                      name="roomPassword"
                      value={item.roomPassword || ""}
                      placeholder="방 비밀번호 입력."
                      onChange={(e) => {
                        onChangeTest(e, index, "roomPassword");
                      }}
                    />
                  </InputBoxRight>
                  <InputBoxRight
                    container
                    item
                    xs={0.5}
                    justifyContent="center"
                  >
                    <CheckBoxComponent
                      name="check"
                      onChange={(e: any) => {
                        onChangeTest(e, index, "disabledCheckbox");
                      }}
                      checked={item.availableCheck}
                    />
                  </InputBoxRight>
                  <InputBoxRight item xs={2.5}>
                    <DateRangePickerComponent
                      id={`daterangepicker-${index}`}
                      placeholder="비활성화 기간을 선택해주세요."
                      format="yyyy-MM-dd"
                      locale="ko"
                      delayUpdate={true}
                      value={
                        item.forbidStartDate && item.forbidEndDate
                          ? [
                              new Date(item.forbidStartDate),
                              new Date(item.forbidEndDate),
                            ]
                          : undefined
                      }
                      onChange={(e: any) => {
                        onChangeTest(e, index, "datepicker");
                      }}
                      showClearButton={false}
                      enabled={item.availableCheck ? true : false}
                    />
                  </InputBoxRight>
                  <InputBoxRight item xs={3}>
                    {item.availableCheck ? (
                      <InputBox
                        type="text"
                        name="message"
                        value={item.message || ""}
                        placeholder="비활성화 시 알림 메세지 입력."
                        onChange={(e) => {
                          onChangeTest(e, index, "message");
                        }}
                      />
                    ) : (
                      <InputBox
                        disabled
                        type="text"
                        name="message"
                        value={item.message || ""}
                        placeholder="비활성화 시 알림 메세지 입력."
                        onChange={(e) => {
                          onChangeTest(e, index, "message");
                        }}
                      />
                    )}
                  </InputBoxRight>
                </InputLayout>
              ))}

            <DongHoPlusIcon>
              <PlusCircle size={30} onClick={onAddDongHo} />
              {donghoList.length > 1 ? (
                <MinusCircle size={30} onClick={onRemoveDongHo} />
              ) : null}
            </DongHoPlusIcon>
          </InputLayout>
        </AccommodationInfoBox>
      </Grid>
      <Grid item xs={12}>
        <AccommodationInfoBox item xs={12}>
          <ReservationDrawerTitle> 휴양소 추가 정보</ReservationDrawerTitle>

          <InputLayout item xs={12}>
            <Grid item xs={6}>
              <Grid>예약 확정 시 사용 횟수 차감 여부</Grid>
              <FormControl>
                <RadioGroup
                  row
                  name="ticketStatus"
                  value={data.ticketStatus || false}
                  onChange={onChange}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="네"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="아니오"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Grid>휴양소 활성화 (비활성화시 임직원 이용 불가)</Grid>
              <FormControl>
                <RadioGroup
                  row
                  name="available"
                  value={data.available || false}
                  onChange={onChange}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="네"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="아니오"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <InputLayout item xs={12}>
              <InputBoxLeft item xs={6}>
                <AccommodationInfoTitle>
                  입실 가이드 URL 주소
                </AccommodationInfoTitle>
                <InputBox
                  type="text"
                  onChange={onChange}
                  name="checkInGuideDocsUrl"
                  value={data.checkInGuideDocsUrl || ""}
                  placeholder="URL 주소를 입력해주세요."
                />
              </InputBoxLeft>

              <InputBoxRight item xs={6}>
                <AccommodationInfoTitle>
                  퇴실 가이드 URL 주소
                </AccommodationInfoTitle>
                <InputBox
                  type="text"
                  onChange={onChange}
                  value={data.checkOutGuideDocsUrl || ""}
                  name="checkOutGuideDocsUrl"
                  placeholder="URL 주소를 입력해주세요."
                />
              </InputBoxRight>
            </InputLayout>

            <Grid item xs={12}>
              <Grid>아이콘</Grid>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="icon"
                  value={data.icon || ""}
                  onChange={onChange}
                >
                  <FormControlLabel
                    value="building"
                    control={<Radio />}
                    label={<Buildings size={20} />}
                  />
                  <FormControlLabel
                    value="warehouse"
                    control={<Radio />}
                    label={<Warehouse size={20} />}
                  />
                  <FormControlLabel
                    value="bridge"
                    control={<Radio />}
                    label={<Bridge size={20} />}
                  />
                  <FormControlLabel
                    value="house"
                    control={<Radio />}
                    label={<House size={20} />}
                  />
                  <FormControlLabel
                    value="houseline"
                    control={<Radio />}
                    label={<HouseLine size={20} />}
                  />
                  <FormControlLabel
                    value="garage"
                    control={<Radio />}
                    label={<Garage size={20} />}
                  />
                  <FormControlLabel
                    value="lighthouse"
                    control={<Radio />}
                    label={<Lighthouse size={20} />}
                  />
                  <FormControlLabel
                    value="park"
                    control={<Radio />}
                    label={<Park size={20} />}
                  />
                  <FormControlLabel
                    value="mountains"
                    control={<Radio />}
                    label={<Mountains size={20} />}
                  />
                  <FormControlLabel
                    value="swimmingpool"
                    control={<Radio />}
                    label={<SwimmingPool size={20} />}
                  />
                  <FormControlLabel
                    value="tent"
                    control={<Radio />}
                    label={<Tent size={20} />}
                  />
                  <FormControlLabel
                    value="tipi"
                    control={<Radio />}
                    label={<Tipi size={20} />}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <AccommodationInfoTitle>
                휴양소 조회 시 보여질 색상{" "}
                {color ? (
                  <ColorExample style={{ backgroundColor: `${color}` }}>
                    휴양소
                  </ColorExample>
                ) : null}
              </AccommodationInfoTitle>
              {/* <HexColorPicker color={color} onChange={setColor} /> */}
              <ColorBigBox>
                {ColorPalette.map((item, index) => {
                  return (
                    <ColorBox
                      onClick={() => {
                        changeColor(item.color);
                      }}
                      key={index}
                      style={{ backgroundColor: `${item.color}` }}
                    ></ColorBox>
                  );
                })}
              </ColorBigBox>
            </Grid>
            <Grid item xs={12}>
              <AccommodationInfoTitle>구비품목</AccommodationInfoTitle>
              <SelectEquippedItems
                list={data.amenities}
                onChange={onChangeEquippedItems}
              />
            </Grid>
          </InputLayout>
        </AccommodationInfoBox>
      </Grid>
      <Grid item xs={12}>
        <AccommodationInfoBox item xs={12}>
          <ReservationDrawerTitle> 휴양소 상세 설명</ReservationDrawerTitle>
          {data.setInfo && (
            <CrossEditor
              ref={refContent}
              name="Inserve"
              params={params}
              value={data.desc1}
              onLoaded={onLoaded}
            />
          )}
        </AccommodationInfoBox>

        <RightLayout>
          {data.id === 0 ? null : (
            <CustomButtonRed onClick={onRemoveAccommodation}>
              삭제
            </CustomButtonRed>
          )}

          <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
        </RightLayout>
      </Grid>
    </AdminTabBox>
  );
}

export default AccommodationInfo;
