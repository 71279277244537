import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  Modal,
  Radio,
  RadioGroup,
  styled,
} from "@mui/material";
import {
  AppliedItem,
  CardDataLayout,
  MainTitle,
  MapButton,
  ModalMobileStyle,
  RaffleContent,
  ReservationTextarea,
  SmallModalStyle,
} from "../../styles/theme";
import {
  VehicleCarInfoBox,
  VehicleCarInfoBoxLimit,
  VehicleCarInfoContentBox,
  VehicleCarInfoTitleBox,
  VehicleCarMemoBox,
  VehicleDrivingBoxModalAlign,
  VehicleDrivingButton,
  VehicleDrivingButtonfont,
  VehicleDrivingButtonfont2,
  VehicleDrivingInfoBox,
  VehicleDrivingInfoContents,
  VehicleDrivingInfoContentsBox,
  VehicleDrivingInfoTitle,
  VehicleDrivingStopButton,
  VehicleReservationButton,
  VehicleReserveDrivingAlign,
  VehicleRightBoxTitle,
} from "../../styles/vehicleStyle";
import { Fragment, useCallback, useEffect, useState } from "react";
import { ModalStyle } from "../../styles/theme";
import { Phone, X } from "@phosphor-icons/react";
import { CustomButtonGreen } from "../../styles/button";
import VehicleReservation from "./VehicleReservation";
import { VehicleApi } from "../../system/api/VehicleApi";
import {
  VehicleDrivingInfo,
  VehicleLastVehicleInfo,
  VehicleMemoInfo,
  VehicleOwnInfo,
} from "../../system/types/Vehicle";
import {
  InitVehicleDrivingInfo,
  InitVehicleLastVehicleInfo,
  InitVehicleMemoInfo,
  InitVehicleOwnInfo,
} from "../../system/types/initObject";
import AlertModal from "../Common/AlertModal";
import { ErrorHandler } from "../../system/ApiService";
import VehicleInfoModal from "./VehicleInfoModal";
import { CustomText } from "../../styles/CustomText";
import {
  BLUEACCENT,
  GRAYPALEBACKGROUND1,
  GRAYPALECONTENTS,
} from "../../styles/color";
import VehicleNotAvailableModal from "./VehicleNotAvailableModal";
import InfoIcon from "@mui/icons-material/Info";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { isoDateFormatter } from "../Common/IsoDateFormatter";
import { MobileSmall, Tablet } from "../../pages/Layout/ResponsiveScreen";
interface VehicleDrivingBoxProps {
  setMyBoxState: (myBoxState: string) => void;
  myBoxState: string;
}

function VehicleDrivingBox({
  setMyBoxState,
  myBoxState,
}: VehicleDrivingBoxProps) {
  const [ownCheck, setOwnCheck] = useState(false);

  // 예약 모달
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const openReservationModal = () => {
    setOwnCheck(false);
    setMyBoxState("");
    setOpen(true);
  };
  const openReservationOwnModal = () => {
    setOwnCheck(true);
    setMyBoxState("");
    setOpen(true);
  };
  // 차량 주행 모달
  const [drivingModalOpen, setDrivingModalOpen] = useState(false);
  const handleDrivingModalClose = () => setDrivingModalOpen(false);
  const openDrivingModal = (drivingStatus: string) => {
    setDrivingModalOpen(true);
    if (drivingStatus === "종료") {
      setDrivingData({ ...drivingData, isStart: false });
    }
  };
  // wayPoints 모달
  const [waypointsModal, setWaypointsModal] = useState(false);
  const handleWayPointsModalClose = () => setWaypointsModal(false);

  const [drivingState, setDrivingState] = useState(false);

  const [drivingData, setDrivingData] = useState<VehicleDrivingInfo>(
    InitVehicleDrivingInfo
  );

  const [beforeData, setBeforeData] = useState<VehicleLastVehicleInfo>(
    InitVehicleLastVehicleInfo
  );
  const [todayData, setTodayData] = useState<VehicleLastVehicleInfo>(
    InitVehicleLastVehicleInfo
  );

  // 문제 발생
  const [problemModalOpen, setProblemModalOpen] = useState(false);
  const handleProblemModalClose = () => setProblemModalOpen(false);
  const openProblemModal = () => {
    setProblemModalOpen(true);
  };

  const [memoData, setMemoData] =
    useState<VehicleMemoInfo>(InitVehicleMemoInfo);
  const onChangeDrivingState = () => {
    VehicleApi.updateDrivingVehicle(drivingData)
      .then(() => {
        refreshDrivingData();
        setMyBoxState("운행시작");
        handleDrivingModalClose();
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  };

  const onSaveDriving = () => {
    VehicleApi.updateDrivingVehicle(drivingData)
      .then(() => {
        refreshDrivingData();
        setMyBoxState("이용완료");
        handleDrivingModalClose();
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  };

  const onSaveMemo = () => {
    VehicleApi.updateVehicleMemo(memoData)
      .then(() => {
        AlertModal("success", "저장이 완료되었습니다.");
        setProblemModalOpen(false);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  };

  const onChangeMemo = (args: any) => {
    setMemoData({
      ...memoData,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };

  const refreshDrivingData = useCallback(() => {
    VehicleApi.getTodayReservationVehicle()
      .then((res) => {
        if (res.data.today?.id) {
          setTodayData(res.data.today);
          setBeforeData(res.data.before);
          setMemoData({
            id: res.data.today.id,
            memo: res.data.today.memo ? res.data.today.memo : "주유 필요",
          });

          if (res.data.today.startMileage) {
            setDrivingData({
              reservationId: res.data.today.id,
              isStart: true,
              mileage: res.data.today.startMileage,
            });
            setDrivingState(true);
            setMyBoxState("이용중");
          } else {
            setDrivingState(false);
            setDrivingData({
              reservationId: res.data.today.id,
              isStart: true,
              mileage: res.data.today.vehicle.totalMileage,
            });
            if (
              res.data.before?.status === "예약중" ||
              res.data.before?.status === "이용중"
            ) {
              setMyBoxState("이용불가");
            } else {
              setMyBoxState("이용중");
            }
          }
        } else {
          setTodayData(InitVehicleLastVehicleInfo);
          setBeforeData(InitVehicleLastVehicleInfo);
          setDrivingData(InitVehicleDrivingInfo);
        }
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [setMyBoxState]);

  useEffect(() => {
    refreshDrivingData();
  }, [refreshDrivingData]);

  const formatNumber = (num: number) => {
    if (!num || isNaN(num)) return "0";
    return num.toLocaleString();
  };
  const removeCommas = (str: string) => {
    if (!str) return "0";
    return str.replace(/,/g, "") || "0";
  };

  const onChangeMileage = (e: any) => {
    const value = e.target.value === "" ? "0" : e.target.value;
    const strWithoutCommas = removeCommas(value);
    const numericValue = parseFloat(strWithoutCommas) || 0;
    setDrivingData({ ...drivingData, mileage: numericValue });
  };
  const onChangeDistance = (e: any) => {
    const value = e.target.value === "" ? "0" : e.target.value;
    const strWithoutCommas = removeCommas(value);
    const numericValue = parseFloat(strWithoutCommas) || 0;
    setDrivingData({
      ...drivingData,
      isStart: false,
      mileage: numericValue,
    });
  };

  const handleOnKeyPress = (e: any) => {
    if (e.key === "Enter") {
      onChangeMileage(e);
    }
  };
  const [directInput, setDirectInput] = useState("주유 필요");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDirectInput((event.target as HTMLInputElement).value);
    if ((event.target as HTMLInputElement).value === "직접입력") {
      setMemoData({
        ...memoData,
        memo: "",
      });
    } else {
      setMemoData({
        ...memoData,
        memo: (event.target as HTMLInputElement).value,
      });
    }
  };
  // 임직원 차량 체크
  const [ownData, setOwnData] = useState<VehicleOwnInfo>(InitVehicleOwnInfo);

  useEffect(() => {
    VehicleApi.checkDesignatedExecutives()
      .then((res) => {
        setOwnData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  return (
    <>
      <VehicleCarInfoBox>
        <CardDataLayout>
          <VehicleCarInfoTitleBox>
            운행 정보
            {todayData.id !== 0 && (
              <VehicleInfoModal data={todayData ? todayData.vehicle : null} />
            )}
          </VehicleCarInfoTitleBox>
        </CardDataLayout>

        {todayData.id !== 0 ? (
          <VehicleCarInfoContentBox>
            <VehicleDrivingInfoBox container>
              <VehicleDrivingInfoContentsBox item>
                <Grid container>
                  <VehicleDrivingInfoTitle item>번호</VehicleDrivingInfoTitle>
                  <VehicleDrivingInfoContents item>
                    {todayData.vehicle.number}
                  </VehicleDrivingInfoContents>
                </Grid>
              </VehicleDrivingInfoContentsBox>
              <VehicleDrivingInfoContentsBox item>
                <Grid container>
                  <VehicleDrivingInfoTitle item>연료</VehicleDrivingInfoTitle>
                  <VehicleDrivingInfoContents item>
                    {todayData.vehicle.fuelType}
                  </VehicleDrivingInfoContents>
                </Grid>
              </VehicleDrivingInfoContentsBox>
            </VehicleDrivingInfoBox>
            <VehicleDrivingInfoBox container>
              <VehicleDrivingInfoTitle item>보험 회사</VehicleDrivingInfoTitle>
              <VehicleDrivingInfoContents item>
                {todayData.vehicle.insurance.company}
                {"(만" + todayData.vehicle.insurance.applicableAge + "세)"}
              </VehicleDrivingInfoContents>
              <VehicleDrivingInfoContents item>
                {" "}
                {todayData.vehicle.insurance.contact}
              </VehicleDrivingInfoContents>
            </VehicleDrivingInfoBox>
            <VehicleDrivingInfoBox container>
              <VehicleDrivingInfoTitle item>담당자</VehicleDrivingInfoTitle>
              <VehicleDrivingInfoContents item>
                {todayData.vehicle?.manager?.name}{" "}
                {todayData.vehicle?.manager?.jobPosition}
              </VehicleDrivingInfoContents>
              <VehicleDrivingInfoContents item>
                {" (" + todayData.vehicle?.manager?.phone + ")"}
              </VehicleDrivingInfoContents>
            </VehicleDrivingInfoBox>
            <VehicleDrivingInfoBox container>
              <VehicleDrivingInfoTitle item>목적지</VehicleDrivingInfoTitle>
              <VehicleDrivingInfoContents item>
                {todayData.waypoints[0]?.placeName ? (
                  <>
                    {todayData.waypoints.length > 1
                      ? todayData.waypoints[0]?.placeName +
                        " 외 " +
                        todayData.waypoints.length +
                        "곳"
                      : todayData.waypoints[0]?.placeName}
                  </>
                ) : (
                  <>
                    {todayData.waypoints.length > 1
                      ? todayData.waypoints[0]?.addressName +
                        " 외 " +
                        todayData.waypoints.length +
                        "곳"
                      : todayData.waypoints[0]?.addressName}
                  </>
                )}
                <InfoIcon
                  color="action"
                  fontSize="small"
                  onClick={() => {
                    setWaypointsModal(true);
                  }}
                  style={{
                    marginTop: "-3px",
                    marginLeft: "5px",
                    cursor: "pointer",
                  }}
                />
              </VehicleDrivingInfoContents>
            </VehicleDrivingInfoBox>
          </VehicleCarInfoContentBox>
        ) : (
          <VehicleCarInfoContentBox
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>예약된 차량이 없습니다.</Grid>
          </VehicleCarInfoContentBox>
        )}
      </VehicleCarInfoBox>
      <VehicleCarInfoBox>
        <CardDataLayout>
          <VehicleCarInfoTitleBox>누적 주행 거리</VehicleCarInfoTitleBox>
        </CardDataLayout>
        <Grid container justifyContent="center">
          {drivingData.reservationId !== 0 ? (
            <Grid item>
              {drivingState ? (
                <Grid container justifyContent="center">
                  <VehicleReserveDrivingAlign item>
                    {todayData.startMileage > 0 &&
                      todayData.startMileage.toLocaleString()}{" "}
                    {todayData.vehicle.unitType}
                  </VehicleReserveDrivingAlign>
                </Grid>
              ) : (
                <Grid container justifyContent="center" alignItems="flex-end">
                  <Input
                    sx={{
                      width: "150px",
                      borderBottom: "1px solid #DCDFE3",
                    }}
                    value={drivingData.mileage.toLocaleString()}
                    onChange={(e) => {
                      onChangeMileage(e);
                    }}
                    onKeyDown={handleOnKeyPress}
                  />
                  <Grid item> {todayData.vehicle.unitType}</Grid>
                </Grid>
              )}
            </Grid>
          ) : (
            <>-</>
          )}
        </Grid>
      </VehicleCarInfoBox>
      <VehicleCarInfoBox>
        <MapButton>
          {todayData.id === 0 && !ownData.id && (
            <VehicleReservationButton onClick={openReservationModal}>
              <VehicleDrivingButtonfont2>차량</VehicleDrivingButtonfont2>
              <VehicleDrivingButtonfont>예약</VehicleDrivingButtonfont>
            </VehicleReservationButton>
          )}
          {todayData.id === 0 && ownData.id && (
            <VehicleReservationButton onClick={openReservationOwnModal}>
              <VehicleDrivingButtonfont2>본인 차량</VehicleDrivingButtonfont2>
              <VehicleDrivingButtonfont>예약</VehicleDrivingButtonfont>
            </VehicleReservationButton>
          )}
          {todayData.id !== 0 && myBoxState === "이용불가" && (
            <VehicleDrivingStopButton
              onClick={() =>
                AlertModal("msg", "이전 사용자에게 연락부탁드립니다.")
              }
            >
              <VehicleDrivingButtonfont2>이용</VehicleDrivingButtonfont2>
              <VehicleDrivingButtonfont>불가</VehicleDrivingButtonfont>
            </VehicleDrivingStopButton>
          )}
          {todayData.id !== 0 &&
            todayData.status === "예약중" &&
            myBoxState !== "이용불가" && (
              <VehicleDrivingButton onClick={() => openDrivingModal("시작")}>
                <VehicleDrivingButtonfont2>운행</VehicleDrivingButtonfont2>
                <VehicleDrivingButtonfont>시작</VehicleDrivingButtonfont>
              </VehicleDrivingButton>
            )}
          {todayData.id !== 0 &&
            todayData.status === "이용중" &&
            myBoxState !== "이용불가" && (
              <VehicleDrivingStopButton
                onClick={() => openDrivingModal("종료")}
              >
                <VehicleDrivingButtonfont2>운행</VehicleDrivingButtonfont2>
                <VehicleDrivingButtonfont>정지</VehicleDrivingButtonfont>
              </VehicleDrivingStopButton>
            )}
        </MapButton>
      </VehicleCarInfoBox>

      <VehicleCarInfoBoxLimit container justifyContent="center">
        {todayData.id !== 0 && myBoxState !== "이용불가" && (
          <TextBlueUnderline>
            <CustomText
              type="subtitle"
              color={BLUEACCENT}
              cursorPointer
              onClick={openProblemModal}
            >
              문제가 발생하셨나요?
            </CustomText>
          </TextBlueUnderline>
        )}
        {myBoxState === "이용불가" && (
          <CustomText type="subtitle">
            차량이 반납되지 않아 이용이 불가능합니다.{" "}
            <VehicleNotAvailableModal />
          </CustomText>
        )}
        {todayData.id === 0 && ownData.id && (
          <Chip
            label="다른 차량 예약"
            color="success"
            variant="outlined"
            onClick={openReservationModal}
          />
        )}
      </VehicleCarInfoBoxLimit>

      <VehicleCarMemoBox>
        <Grid container justifyContent="space-between" alignItems="center">
          <VehicleRightBoxTitle item>이전 차량 사용 기록</VehicleRightBoxTitle>
          <Grid>
            {beforeData?.id !== 0 && beforeData?.status && (
              <Chip
                label={beforeData.status}
                variant="outlined"
                color="info"
                style={{ cursor: "pointer" }}
              />
            )}
          </Grid>
        </Grid>

        {beforeData?.id !== 0 ? (
          <Grid container>
            <Grid container>
              <CustomText type="subtitle">사용자</CustomText>
              <VehicleDrivingInfoContents item>
                {beforeData?.employee.name ? (
                  <>
                    {beforeData?.employee.name + " ( "}
                    <Phone size={20} />
                    {beforeData?.employee.phone + " )"}
                  </>
                ) : (
                  "정보 없음"
                )}
              </VehicleDrivingInfoContents>
            </Grid>
            <Grid container>
              <CustomText type="subtitle">이동 거리 (시간)</CustomText>
              <VehicleDrivingInfoContents item>
                {beforeData?.startMileage !== null &&
                beforeData?.endMileage !== null &&
                beforeData?.startMileage >= 0 &&
                beforeData?.endMileage >= 0
                  ? (
                      beforeData.endMileage - beforeData.startMileage
                    ).toLocaleString() + " km (시간)"
                  : " - km (시간)"}
              </VehicleDrivingInfoContents>
            </Grid>
            <Grid container>
              <CustomText type="subtitle">메모</CustomText>
              <VehicleDrivingInfoContents item>
                {beforeData?.memo ? beforeData.memo : "내용 없음"}
              </VehicleDrivingInfoContents>
            </Grid>
          </Grid>
        ) : (
          <Grid container justifyContent="center" mt="20px">
            이전 차량 사용 기록이 없습니다.
          </Grid>
        )}
      </VehicleCarMemoBox>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fragment>
          <MobileSmall>
            <Box sx={ModalMobileStyle}>
              {ownCheck ? (
                <VehicleReservation
                  handleClose={handleClose}
                  refreshDrivingData={refreshDrivingData}
                  setMyBoxState={setMyBoxState}
                  vehicleId={ownData.id}
                  selectedDate={isoDateFormatter(new Date())}
                  setTodayData={setTodayData}
                  setBeforeData={setBeforeData}
                  setMemoData={setMemoData}
                  setDrivingState={setDrivingState}
                  drivingData={drivingData}
                  setDrivingData={setDrivingData}
                  setMyBox={setMyBoxState}
                  ownCheck={ownCheck}
                />
              ) : (
                <VehicleReservation
                  handleClose={handleClose}
                  refreshDrivingData={refreshDrivingData}
                  setMyBoxState={setMyBoxState}
                />
              )}
            </Box>
          </MobileSmall>
          <Tablet>
            <Box sx={ModalStyle}>
              {ownCheck ? (
                <VehicleReservation
                  handleClose={handleClose}
                  refreshDrivingData={refreshDrivingData}
                  setMyBoxState={setMyBoxState}
                  vehicleId={ownData.id}
                  selectedDate={isoDateFormatter(new Date())}
                  setTodayData={setTodayData}
                  setBeforeData={setBeforeData}
                  setMemoData={setMemoData}
                  setDrivingState={setDrivingState}
                  drivingData={drivingData}
                  setDrivingData={setDrivingData}
                  setMyBox={setMyBoxState}
                  ownCheck={ownCheck}
                />
              ) : (
                <VehicleReservation
                  handleClose={handleClose}
                  refreshDrivingData={refreshDrivingData}
                  setMyBoxState={setMyBoxState}
                />
              )}
            </Box>
          </Tablet>
        </Fragment>
      </Modal>
      <Modal
        open={drivingModalOpen}
        onClose={handleDrivingModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={SmallModalStyle}>
          {drivingState ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <RaffleContent>
                    <MainTitle>운행종료</MainTitle>
                    <AppliedItem onClick={handleDrivingModalClose}>
                      <X size={32} />
                    </AppliedItem>
                  </RaffleContent>
                  <CustomText type="subtitle" bold400 color={GRAYPALECONTENTS}>
                    현재 누적 주행 거리를 입력해주세요.
                  </CustomText>
                  <VehicleDrivingBoxModalAlign
                    container
                    justifyContent="center"
                  >
                    <Grid item>
                      <Input
                        value={formatNumber(drivingData.mileage)}
                        placeholder="현재 누적 주행 거리"
                        sx={{
                          width: "220px",
                          borderBottom: "1px solid #DCDFE3",
                          fontSize: "25px",
                        }}
                        onChange={onChangeDistance}
                      />
                    </Grid>
                    <Grid item ml="10px">
                      {todayData.vehicle?.unitType}
                    </Grid>
                  </VehicleDrivingBoxModalAlign>

                  <Grid container justifyContent="right">
                    <CustomButtonGreen onClick={onSaveDriving}>
                      저장
                    </CustomButtonGreen>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <RaffleContent>
                    <MainTitle>운행 시작</MainTitle>
                    <AppliedItem onClick={handleDrivingModalClose}>
                      <X size={32} />
                    </AppliedItem>
                  </RaffleContent>
                  <Grid
                    item
                    xs={12}
                    style={{ width: "100%", wordWrap: "break-word" }}
                  >
                    <CustomText
                      type="subtitle"
                      bold400
                      color={GRAYPALECONTENTS}
                    >
                      누적 주행 거리가 맞게 입력됐는지 확인해주세요.
                    </CustomText>
                  </Grid>

                  <VehicleDrivingBoxModalAlign
                    container
                    justifyContent="center"
                    alignItems="flex-end"
                  >
                    <Grid item>
                      <Input
                        sx={{
                          width: "150px",
                          borderBottom: "1px solid #DCDFE3",
                          fontSize: "25px",
                        }}
                        value={formatNumber(drivingData.mileage)}
                        onChange={(e) => {
                          onChangeMileage(e);
                        }}
                        onKeyDown={handleOnKeyPress}
                      />
                    </Grid>
                    <Grid item ml="10px">
                      {todayData.vehicle?.unitType}
                    </Grid>
                  </VehicleDrivingBoxModalAlign>

                  <Grid container justifyContent="right">
                    <CustomButtonGreen onClick={onChangeDrivingState}>
                      운행 시작
                    </CustomButtonGreen>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Modal>
      <Modal
        open={problemModalOpen}
        onClose={handleProblemModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={SmallModalStyle}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RaffleContent>
                <MainTitle>문제 발생</MainTitle>
                <AppliedItem onClick={handleProblemModalClose}>
                  <X size={32} />
                </AppliedItem>
              </RaffleContent>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <RadioGroup value={directInput} onChange={handleChange}>
                  <FormControlLabel
                    value="주유 필요"
                    control={<Radio />}
                    label="주유 필요"
                  />
                  <FormControlLabel
                    value="현지출퇴근"
                    control={<Radio />}
                    label="현지출퇴근"
                  />
                  <FormControlLabel
                    value="직접입력"
                    control={<Radio />}
                    label="직접입력"
                  />
                </RadioGroup>
              </FormControl>
              <Grid item xs={12} style={{ height: "32px" }}>
                {directInput === "직접입력" && (
                  <ReservationTextarea
                    name="memo"
                    onChange={onChangeMemo}
                    placeholder="어떤 문제가 발생했는지 알려주세요."
                    value={memoData.memo || ""}
                  />
                )}
              </Grid>
            </Grid>
            <Grid container justifyContent="right" mt="5px">
              <CustomButtonGreen onClick={onSaveMemo}>저장</CustomButtonGreen>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={waypointsModal}
        onClose={handleWayPointsModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={SmallModalStyle}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RaffleContent>
                <MainTitle>목적지 정보</MainTitle>
                <AppliedItem onClick={handleWayPointsModalClose}>
                  <X size={32} />
                </AppliedItem>
              </RaffleContent>
            </Grid>
            <Grid container item xs={12}>
              {todayData.waypoints.map((item, idx) => {
                return (
                  <Grid
                    container
                    item
                    xs={23}
                    key={idx}
                    justifyContent="center"
                  >
                    {idx !== 0 && (
                      <Grid item>
                        <KeyboardArrowDownIcon />
                      </Grid>
                    )}
                    <WaypointsBox
                      container
                      item
                      xs={12}
                      justifyContent="center"
                    >
                      {item.placeName && (
                        <Grid container item xs={12} justifyContent="center">
                          <CustomText type="subtitle2">
                            {item.placeName}
                          </CustomText>
                        </Grid>
                      )}
                      {item.roadAddressName && (
                        <Grid container item xs={12} justifyContent="center">
                          {item.roadAddressName}
                        </Grid>
                      )}
                      {item.addressName && (
                        <Grid container item xs={12} justifyContent="center">
                          {item.addressName}
                        </Grid>
                      )}
                    </WaypointsBox>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default VehicleDrivingBox;

const TextBlueUnderline = styled(Grid)(() => ({
  textDecoration: `underline ${BLUEACCENT}`,
}));

const WaypointsBox = styled(Grid)(() => ({
  borderRadius: "8px",
  backgroundColor: GRAYPALEBACKGROUND1,
  padding: "10px 0px",
  margin: "5px 0px",
}));
