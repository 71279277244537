import Swal from "sweetalert2";
import { AlertModalType } from "../../../system/types/modal/Modal";
import { GREENCONTENTS } from "../../../styles/color";

const memoModal = (
  type: AlertModalType,
  message?: string,
  content?: string
) => {
  return Swal.fire({
    title:
      "<div style='font-family:Noto sans CJK KR;font-size:20px;margin-bottom:10px'>" +
      message +
      "</div>",
    text: content,
    icon: "success",
    confirmButtonText: "확인",
    confirmButtonColor: GREENCONTENTS,
  });
};

export default memoModal;
