import { Grid } from "@mui/material";

import { AppliedItem, MainTitle, RaffleContent } from "../../styles/theme";
import { X } from "@phosphor-icons/react";
import {
  VehicleReserveBigBox,
  VehicleReserveMobileBigBox,
} from "../../styles/vehicleStyle";
import { useEffect, useState } from "react";
import { CustomButtonGreen } from "../../styles/button";
import {
  VehicleAvailableInfo,
  VehicleDrivingInfo,
  VehicleLastVehicleInfo,
  VehicleMemoInfo,
  VehicleReservationInfo,
} from "../../system/types/Vehicle";

import { isoDateFormatter } from "../Common/IsoDateFormatter";
import { VehicleApi } from "../../system/api/VehicleApi";
import { ErrorHandler } from "../../system/ApiService";
import AlertModal from "../Common/AlertModal";
import {
  InitVehicleLastVehicleInfo,
  InitVehicleReservationInfo,
} from "../../system/types/initObject";

import { useLoadingDispatch } from "../../system/context/LoadingContext";

import VRSTime from "./Reservation/VRSTime";
import VRSDestination from "./Reservation/VRSDestination";
import VRSVehicle from "./Reservation/VRSVehicle";
import { MobileSmall, Tablet } from "../../pages/Layout/ResponsiveScreen";
interface VehicleReservationProps {
  handleClose: () => void;
  refreshDrivingData?: () => void;
  setMyBoxState?: (myBoxState: string) => void;
  selectedDate?: string | undefined;
  vehicleId?: number;
  availableTime?: string;
  setTodayData?: (dt: VehicleLastVehicleInfo) => void;
  setBeforeData?: (dt: VehicleLastVehicleInfo) => void;
  setMemoData?: (dt: VehicleMemoInfo) => void;
  setDrivingState?: (dt: boolean) => void;
  drivingData?: VehicleDrivingInfo;
  setDrivingData?: (dt: VehicleDrivingInfo) => void;
  setMyBox?: (dt: string) => void;
  ownCheck?: boolean;
}

function validation(data: VehicleReservationInfo) {
  if (data.startDate === "") {
    AlertModal("check", "차량을 예약하려면 원하는 날짜를 입력해 주세요.");
    return false;
  } else if (data.time === "") {
    AlertModal("check", "차량을 예약하려면 원하는 시간대를 입력해 주세요.");
    return false;
  } else if (data.vehicleId === 0) {
    AlertModal("check", "원하는 차량을 선택해 주세요.");
    return false;
  } else if (
    data.waypoints.filter((item) => item.kakaoLocId === 0).length > 0
  ) {
    AlertModal("check", "목적지를 모두 입력해주세요.");
    return false;
  }
  return true;
}

function VehicleReservation({
  handleClose,
  refreshDrivingData,
  setMyBoxState,
  selectedDate,
  vehicleId,
  availableTime,
  setTodayData,
  setBeforeData,
  setMemoData,
  setDrivingState,
  drivingData,
  setDrivingData,
  ownCheck,
}: VehicleReservationProps) {
  const [reservationData, setReservationData] =
    useState<VehicleReservationInfo>(InitVehicleReservationInfo);
  const [timeSelect, setTimeSelect] = useState(true);
  const [carSelect, setCarSelect] = useState(true);
  const [enterDestination, setEnterDestination] = useState(false);
  const Loading = useLoadingDispatch();
  const viewportHeight = window.innerHeight;
  const changeSelect = (type: string) => {
    if (type === "time") {
      setTimeSelect(!timeSelect);
    } else if (type === "car") {
      setCarSelect(!carSelect);
    } else if (type === "destination") {
      setEnterDestination(!enterDestination);
    }
  };

  // 시간 선택

  const onChangeDate = (args: React.ChangeEvent<HTMLInputElement>): void => {
    setReservationData({
      ...reservationData,
      startDate: isoDateFormatter(new Date(args.target.value)),
      vehicleId: 0,
    });
  };
  const onChange = (args: React.ChangeEvent<HTMLInputElement>): void => {
    setReservationData({
      ...reservationData,
      [(args.target as HTMLInputElement).name]: args.target.value,
      vehicleId: 0,
    });
  };

  const onChangeVehicle = (args: any) => {
    const newValue = parseInt(args.target.value, 10);
    if (!isNaN(newValue)) {
      setReservationData({
        ...reservationData,
        [(args.target as HTMLInputElement).name]: newValue,
      });
      setCarSelect(false);
    }
  };
  // searchword
  const [inputWord, setInputWord] = useState("");
  const [searchWord, setSearchWord] = useState("");
  const [sizeType, setSizeType] = useState("");
  const [shapeType, setShapeType] = useState("");

  const onChangeSearch = (args: any, type: string) => {
    if (type === "search") {
      setInputWord(args.target.value);
    } else if (type === "sizeType") {
      setSizeType(args.target.value);
    } else if (type === "shapeType") {
      setShapeType(args.target.value);
    }
  };
  const onClickSearch = () => {
    setSearchWord(inputWord);
  };

  // 예약 가능한 차량 조회
  const [availableVehicleData, setAvailableVehicleData] = useState<
    VehicleAvailableInfo[]
  >([]);
  useEffect(() => {
    if (selectedDate && vehicleId) {
      setReservationData((prevData) => ({
        ...prevData,
        vehicleId: vehicleId,
        startDate: selectedDate,
        time: availableTime ? availableTime : "종일",
      }));
      setCarSelect(false);
      setTimeSelect(false);
    }
  }, [vehicleId, selectedDate, availableTime]);

  useEffect(() => {
    if (reservationData.startDate && reservationData.time) {
      VehicleApi.getAvailableVehicle(
        selectedDate ? selectedDate : reservationData.startDate,
        availableTime ? availableTime : reservationData.time,
        sizeType,
        shapeType,
        searchWord
      )
        .then((res) => {
          // VehicleAvailableInfo 타입을 명시적으로 지정하여 필터링
          const filteredVehicles = res.data.filter(
            (item: VehicleAvailableInfo) => item.vehicle.available === true
          );
          setAvailableVehicleData(filteredVehicles);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    }
  }, [
    sizeType,
    shapeType,
    searchWord,
    reservationData.startDate,
    reservationData.time,
  ]);

  const onSave = () => {
    if (validation(reservationData)) {
      Loading({ type: "LOADING" });
      VehicleApi.createVehicleReservation(reservationData)
        .then(() => {
          AlertModal("success", "차량 예약이 완료되었습니다.");
          handleClose();
          if (refreshDrivingData) {
            refreshDrivingData();
          }
          if (setMyBoxState) {
            setMyBoxState("예약완료");
          }
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        })
        .finally(() => {
          Loading({ type: "COMPLETE" });
        });
    }
  };

  const onSaveOwn = () => {
    if (validation(reservationData)) {
      Loading({ type: "LOADING" });

      VehicleApi.createVehicleReservation(reservationData)
        .then(() => {
          return VehicleApi.getTodayReservationVehicle();
        })
        .then((res) => {
          if (
            res.data.today?.id &&
            setTodayData &&
            setBeforeData &&
            setMemoData &&
            setDrivingData &&
            setDrivingState &&
            setMyBoxState
          ) {
            setTodayData(res.data.today);
            setBeforeData(res.data.before);
            setMemoData({
              id: res.data.today.id,
              memo: res.data.today.memo ? res.data.today.memo : "주유 필요",
            });
            setDrivingState(true);
            const newDrivingData = {
              reservationId: res.data.today.id,
              isStart: true,
              mileage: res.data.today.vehicle.totalMileage,
            };
            setDrivingData(newDrivingData);
            setMyBoxState("이용중");
            return VehicleApi.updateDrivingVehicle(newDrivingData);
          } else {
            if (setTodayData && setBeforeData) {
              setTodayData(InitVehicleLastVehicleInfo);
              setBeforeData(InitVehicleLastVehicleInfo);
            }
            return Promise.reject(refreshDrivingData && refreshDrivingData());
          }
        })
        .then(() => {
          if (setMyBoxState) {
            setMyBoxState("운행시작");
          }
          handleClose();
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);

          handleClose();
        })
        .finally(() => {
          if (refreshDrivingData) {
            refreshDrivingData();
          }
          Loading({ type: "COMPLETE" });
        });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RaffleContent>
            <MainTitle>차량 예약</MainTitle>
            <AppliedItem onClick={handleClose}>
              <X size={32} />
            </AppliedItem>
          </RaffleContent>
        </Grid>
        <MobileSmall>
          <VehicleReserveMobileBigBox item xs={12} viewheight={viewportHeight}>
            <VRSTime
              reservationData={reservationData}
              changeSelect={changeSelect}
              timeSelect={timeSelect}
              onChangeDate={onChangeDate}
              onChange={onChange}
              selectedDate={selectedDate}
            />
            <VRSVehicle
              reservationData={reservationData}
              changeSelect={changeSelect}
              availableVehicleData={availableVehicleData}
              carSelect={carSelect}
              onChangeSearch={onChangeSearch}
              onChangeVehicle={onChangeVehicle}
              onClickSearch={onClickSearch}
              vehicleId={vehicleId}
            />
            <VRSDestination
              reservationData={reservationData}
              changeSelect={changeSelect}
              enterDestination={enterDestination}
              setReservationData={setReservationData}
            />
          </VehicleReserveMobileBigBox>
        </MobileSmall>
        <Tablet>
          <VehicleReserveBigBox item xs={12} viewheight={viewportHeight}>
            <VRSTime
              reservationData={reservationData}
              changeSelect={changeSelect}
              timeSelect={timeSelect}
              onChangeDate={onChangeDate}
              onChange={onChange}
              selectedDate={selectedDate}
            />
            <VRSVehicle
              reservationData={reservationData}
              changeSelect={changeSelect}
              availableVehicleData={availableVehicleData}
              carSelect={carSelect}
              onChangeSearch={onChangeSearch}
              onChangeVehicle={onChangeVehicle}
              onClickSearch={onClickSearch}
              vehicleId={vehicleId}
            />
            <VRSDestination
              reservationData={reservationData}
              changeSelect={changeSelect}
              enterDestination={enterDestination}
              setReservationData={setReservationData}
            />
          </VehicleReserveBigBox>
        </Tablet>
        <Grid container justifyContent="right">
          {ownCheck ? (
            <CustomButtonGreen onClick={onSaveOwn}>운행 시작</CustomButtonGreen>
          ) : (
            <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default VehicleReservation;
