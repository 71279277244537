import { styled } from "@mui/material/styles";
import {
  GRAYNeutralBACKGROUND1,
  GRAYNeutralCONTENTS,
  GRAYNeutralTITLE,
  GRAYPALECONTENTS,
  GRAYPALEDISABLED,
  GRAYPALETITLE,
  GREENACCENT,
  ORANGEACCENT,
  PRIMARY,
} from "./color";
import { Grid, IconButton } from "@mui/material";

// 휴양소
export const AccommodationBox = styled("div")(() => ({
  border: "1px solid #EDEDED",
  justifyContent: "space-evenly",
  display: "flex",
  margin: "2rem auto",
  borderRadius: "8px",
  maxWidth: "70rem",
  width: "98%",
  textAlign: "center",
  height: "4.2rem",
  alignItems: "center",
  backgroundColor: "#ffffff",
}));

export const AccommodationSelectBox = styled("div")(() => ({
  border: "1px solid #EDEDED",
  justifyContent: "space-evenly",
  display: "flex",
  margin: "2rem auto",
  borderRadius: "8px",
  width: "98%",
  maxWidth: "80rem",
  textAlign: "center",
  height: "4.2rem",
  alignItems: "center",
  backgroundColor: "#ffffff",
}));

export const AccommodationIcon = styled("div")(() => ({
  color: GRAYNeutralTITLE,
}));

export const AccommodationPointerIcon = styled("div")(() => ({
  color: GRAYNeutralTITLE,
  cursor: "pointer",
}));

export const AccommodationDisabledIcon = styled("div")(() => ({
  color: GRAYPALEDISABLED,
}));

export const SelectedIcon = styled("div")(() => ({
  color: PRIMARY,
  borderBottom: "0.2rem solid #971B2F",
  cursor: "pointer",
}));

export const ReservationViewGap = styled("div")(() => ({
  marginTop: "75px",
}));

// 휴양소 조회
export const AsOfDate = styled(Grid)(() => ({
  justifyContent: "flex-end",
  color: GRAYPALECONTENTS,
  marginBottom: "5px",
}));

export const ScheduleTitle = styled(Grid)(() => ({
  AlignItems: "center",
}));

export const ScheduleTitleMobile = styled(Grid)(() => ({
  AlignItems: "center",
  marginBottom: "10px",
}));

export const ScheduleLegendBackgroundBox = styled("div")(() => ({
  border: "1px solid #DCDFE3",
  borderRadius: "5px",
  width: "50%",
  display: "relative",
  height: "40px",
  zIndex: "-1",
  left: 0,
  right: 0,
  margin: "-4.5rem auto 0.4rem auto",
}));

export const ScheduleLegendBackgroundMobileBox = styled("div")(() => ({
  border: "1px solid #DCDFE3",
  borderRadius: "5px",
  width: "98%",
  display: "relative",
  height: "45px",
  zIndex: "-1",
  left: 0,
  right: 0,
  margin: "-5.6rem auto 0.4rem 1rem",
}));

export const ScheduleLegendTitleBox = styled(Grid)(() => ({
  display: "flex",
  alignItems: "baseline",
  justifyContent: "center",
}));

export const ScheduleLegendMobileTitleBox = styled("div")(() => ({
  marginBottom: "-9px",
  marginLeft: "20px",
  fontSize: "0.8rem",
  textAlign: "center",
  backgroundColor: "#ffffff",
  width: "90px",
}));

export const ScheduleLegend = styled(Grid)(() => ({
  marginBottom: "10px",
  fontWeight: "600",
}));

export const ScheduleLegendTitleLayout = styled(Grid)(() => ({
  marginRight: "3px",
}));

export const MakeReservationButtonLayout = styled(Grid)(() => ({
  marginBottom: "10px",
}));

interface ScheduleLegendItemProps {
  color: string;
}

export const ScheduleLegendItem = styled(Grid)(
  ({ color }: ScheduleLegendItemProps) => ({
    cursor: "pointer",
    border: `1px solid ${color}`,
    padding: "3px 7px",
    borderRadius: "5px",
    color: color,
    minHeight: "40px",
    fontWeight: 600,
    "&:hover": { backgroundColor: color, color: "#ffffff" },
  })
);

export const ScheduleLegendDisabledItem = styled(Grid)(
  ({ color }: ScheduleLegendItemProps) => ({
    cursor: "pointer",
    border: `1px solid ${GRAYPALEDISABLED}`,
    padding: "3px 7px",
    borderRadius: "5px",
    color: GRAYPALEDISABLED,
    minHeight: "40px",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: color,
      color: "#ffffff",
      border: `1px solid ${color}`,
    },
  })
);

interface ScheduleItemProps {
  color: string;
}

export const ScheduleItemBox = styled("div")(
  ({ color }: ScheduleItemProps) => ({
    display: "flex",
    width: "100%",
    minWidth: "150px",
    border: `1px solid ${color}`,
    paddingRight: "5px",
    borderRadius: "5px",
    alignItems: "center",
    cursor: "default",
    backgroundColor: "#ffffff",
    fontSize: "0.8rem",
  })
);

export const ScheduleItemPointerBox = styled("div")(
  ({ color }: ScheduleItemProps) => ({
    display: "flex",
    width: "100%",
    minWidth: "150px",
    paddingRight: "5px",
    border: `1px solid ${color}`,
    borderRadius: "5px",
    alignItems: "center",
    backgroundColor: "#ffffff",
    fontSize: "0.8rem",
    cursor: "pointer",
  })
);

export const ScheduleSmallItemBox = styled("div")(
  ({ color }: ScheduleLegendItemProps) => ({
    color: `${color}`,
    marginRight: "5px",
    fontSize: "0.8rem",
    paddingLeft: "5px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  })
);

export const ScheduleSmallItemDefaultBox = styled("div")(
  ({ color }: ScheduleLegendItemProps) => ({
    color: `${color}`,
    marginRight: "5px",
    cursor: "default",
    fontSize: "0.8rem",
    paddingLeft: "5px",
  })
);

export const HolidayInfo = styled("div")(() => ({
  float: "right",
  marginTop: "-50px",
}));

export const TooltipIcon = styled("div")(() => ({
  color: GRAYPALECONTENTS,
  marginLeft: "5px",
  marginTop: "-2px",
}));

export const CircleItem = styled("div")(({ color }: any) => ({
  border: `1px solid ${color}`,
  backgroundColor: `${color}`,
  width: "10px",
  height: "10px",
  borderRadius: "50%",
  margin: "0px 10px",
}));

export const AccommodationCircleItem = styled("div")(() => ({
  border: `1px solid #109972`,
  backgroundColor: `#109972`,
  width: "8px",
  height: "8px",
  borderRadius: "50%",
  marginLeft: "3px",
}));

export const AccommodationEmptyItem = styled("div")(() => ({
  border: `1px solid #B3BBC5`,
  backgroundColor: `#B3BBC5`,
  width: "8px",
  height: "8px",
  borderRadius: "50%",
  marginLeft: "3px",
}));

// 휴양소 예약
export const BackgroundBox = styled("div")(() => ({
  width: "99%",
  backgroundColor: GRAYNeutralBACKGROUND1,
  position: "relative",
  height: "19rem",
  margin: "-23.5rem auto",
  zIndex: "-1",
  borderRadius: "8px",
  left: 0,
  right: 0,
}));

export const AdminCheckBackgroundBox = styled("div")(() => ({
  width: "99%",
  backgroundColor: GRAYNeutralBACKGROUND1,
  position: "relative",
  height: "28rem",
  margin: "-32.5rem auto",
  zIndex: "-1",
  borderRadius: "8px",
  left: 0,
  right: 0,
}));

export const AdminBackgroundBox = styled("div")(() => ({
  width: "99%",
  backgroundColor: GRAYNeutralBACKGROUND1,
  position: "relative",
  height: "21.5rem",
  margin: "-26rem auto",
  zIndex: "-1",
  borderRadius: "8px",
  left: 0,
  right: 0,
}));

export const AccommodationDataBox = styled(Grid)(() => ({
  display: "flex",
  flexWrap: "wrap",
}));

export const AdminReservationBox = styled("div")(() => ({
  textAlign: "center",
  marginBottom: "15px",
}));

export const PcModal = styled("div")(() => ({
  width: "50rem",
  height: "20rem",
  zIndex: "999",
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#ffffff",
  borderRadius: "8px",
  border: "1px solid #EDEDED",
  padding: "5rem",
}));

export const MobileModal = styled("div")(() => ({
  width: "85%",
  height: "20rem",
  zIndex: "999",
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#ffffff",
  borderRadius: "8px",
  border: "1px solid #EDEDED",
  padding: "5rem",
}));

// 입실, 퇴실 전 체크리스트
export const CheckListBox = styled("div")(() => ({
  height: "calc(100% - 80px)",
  overflow: "scroll",
}));

export const CheckListBoxItem = styled("div")(() => ({
  margin: "10px 0px",
}));

export const DisabledCheckList = styled("span")(() => ({
  color: GRAYPALEDISABLED,
  marginRight: "5px",
  cursor: "pointer",
}));

export const SelectedCheckList = styled("span")(() => ({
  color: GREENACCENT,
  marginRight: "5px",
}));

export const AdminAccommodationInfoBox = styled("div")(() => ({
  width: "100%",
  resize: "none",
  height: "5rem",
  border: "1px solid #DCDFE3",
  borderRadius: "8px",
  color: "#000000",
  padding: "5px 15px",
  margin: "20px 5px 5px 5px",
}));

// 사용 후 보고
export const ReportAfterUseBox = styled("div")(() => ({
  margin: "20px 0px",
  height: "70%",
  maxHeight: "560px",
  overflow: "none",
}));

export const ReportAfterUseItemBox = styled("div")(() => ({
  margin: "10px 0px",
}));

export const ReportAfterUseTitleBox = styled("div")(() => ({
  cursor: "pointer",
  color: GRAYPALETITLE,
}));

export const ReportAfterUseDisabledTitleBox = styled("div")(() => ({
  color: GRAYPALEDISABLED,
}));

export const ReportAfterUseContentsBox = styled("div")(() => ({
  margin: "5px 0px 5px 10px",
  color: GRAYPALECONTENTS,
}));

export const ReportAfterImgBox = styled("div")(() => ({
  margin: "5px",
  display: "flex",
  flexWrap: "wrap",
}));

export const ReportAfterImgItem = styled("div")(() => ({}));

export const DeleteIconButton = styled(IconButton)(() => ({
  marginLeft: "-30px",
  marginTop: "-55px",
}));

// 나의 예약(모달)
export const SelectAccommodationStatus = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  margin: "70px auto 20px auto",
  maxWidth: "800px",
  width: "100%",
}));

export const SelectAccommodationItem = styled("div")(() => ({
  textAlign: "center",
  cursor: "pointer",
}));

export const SelectAccommodationText = styled("div")(() => ({
  margin: "20px 0px",
  color: GRAYPALETITLE,
  width: "100%",
}));

export const ModalNoDataText = styled("div")(() => ({
  marginTop: "150px",
  textAlign: "center",
  color: GRAYPALETITLE,
}));

export const ModalTextScrollBox = styled("div")(() => ({
  height: "500px",
  overflow: "scroll",
}));

export const AccommodationStatusIcon = styled("img")(() => ({
  width: "40%",
}));

export const CheckoutImgUploadInfoBox = styled("div")(() => ({
  margin: "20px 0px",
}));

export const CheckoutImgUploadInfoTitle = styled("div")(() => ({
  color: GRAYPALETITLE,
}));

export const CheckoutImgUploadInfoContent = styled("div")(() => ({
  color: GRAYPALECONTENTS,
  margin: "5px 0px",
}));

// 관리자 - 휴양소 정보

export const AdminAccommodationMemoInput = styled("textarea")(() => ({
  fontFamily: "Noto sans CJK KR",
  margin: "0.5rem auto",
  maxWidth: "70rem",
  width: "98%",
  resize: "none",
  height: "5rem",
  border: "1px solid #DCDFE3",
  borderRadius: "8px",
  "&:hover": { border: "1px solid #81848B" },
  "&:focus": { border: "1px solid #81848B", outline: "none" },
  "&:active": { border: "1px solid #81848B" },
}));

export const AdminAccomodationBackgroundBox = styled("div")(() => ({
  width: "99%",
  backgroundColor: GRAYNeutralBACKGROUND1,
  position: "relative",
  height: "19rem",
  margin: "-21.5rem auto",
  zIndex: "-1",
  borderRadius: "8px",
  left: 0,
  right: 0,
}));

export const AdminAccomodationEmptyBox = styled("div")(() => ({
  margin: "25.5rem 0rem 1rem auto",
  display: "flex",
}));

export const AccommodationInfoBox = styled(Grid)(() => ({
  backgroundColor: GRAYNeutralBACKGROUND1,
  borderRadius: "8px",
  margin: "1rem 0rem",
  padding: "0rem 1rem 1.5rem 1rem",
}));

export const EmployeeInfoBox = styled(Grid)(() => ({
  backgroundColor: GRAYNeutralBACKGROUND1,
  borderRadius: "8px",
  padding: "0rem 1rem 0.5rem 1rem",
}));

export const AccommodationInfoTitle = styled(Grid)(() => ({
  margin: "0.5rem 0rem",
}));

export const AccommodationInfoIcon = styled("span")(() => ({
  color: GRAYNeutralTITLE,
  cursor: "pointer",
}));

export const ColorBigBox = styled("div")(() => ({
  width: "18rem",
  display: "flex",
  flexWrap: "wrap",
}));

export const ColorBox = styled("div")(() => ({
  width: "2rem",
  height: "2rem",
  cursor: "pointer",
}));

export const ColorExample = styled("span")(() => ({
  color: "#ffffff",
  height: "1.5rem",
  borderRadius: "8px",
  textAlign: "center",
}));

export const DongHoPlusIcon = styled("div")(() => ({
  marginTop: "1rem",
  color: GRAYNeutralCONTENTS,
  cursor: "pointer",
}));

export const AccommodationAlert = styled(Grid)(() => ({
  width: "100%",
  color: ORANGEACCENT,
  marginBottom: "10px",
}));
