import { Grid } from "@mui/material";
import { VehicleInfo } from "../../../system/types/Vehicle";
import {
  CardDataBox,
  CardDataContent,
  CardDataLayout,
  RaffleContent,
  ThickText,
} from "../../../styles/theme";
import MyAccommodationChip from "../../Common/Accommodation/MyAccommodationChip";
import { CustomButtonYellow } from "../../../styles/button";
import { useState } from "react";
import VehicleEdit from "./VehicleEdit";
import { InitVehicleInfo } from "../../../system/types/initObject";

interface VehicleManagementCardProps {
  vehicleData: VehicleInfo[];
  refreshData: () => void;
}

function VehicleManagementCard({
  vehicleData,
  refreshData,
}: VehicleManagementCardProps) {
  const [data, setData] = useState<VehicleInfo>(InitVehicleInfo);
  const [open, setOpen] = useState(false);
  const handleOpen = (item: VehicleInfo) => {
    setOpen(true);
    setData(item);
  };
  const handleClose = () => setOpen(false);

  return (
    <Grid item xs={12}>
      <CardDataLayout>
        {vehicleData.map((item: VehicleInfo, idx) => {
          return (
            <CardDataBox key={idx}>
              <Grid item xs={12}>
                <CardDataContent>
                  <RaffleContent>
                    <ThickText>
                      {" "}
                      <MyAccommodationChip status={item.usageType} />
                    </ThickText>
                  </RaffleContent>
                </CardDataContent>
                <CardDataContent>
                  <Grid container justifyContent="space-between">
                    <Grid>{item.number}</Grid>
                    <Grid>
                      {item.seater + " 인승"} / {item.fuelType}
                    </Grid>
                  </Grid>
                </CardDataContent>
                <CardDataContent>
                  <Grid container>
                    <ThickText style={{ marginRight: "5px" }}>
                      누적 주행 거리
                    </ThickText>
                    {item.totalMileage.toLocaleString() + " " + item.unitType}
                  </Grid>
                </CardDataContent>
                <CardDataContent>
                  <Grid container>
                    <ThickText style={{ marginRight: "5px" }}>
                      차량 보험
                    </ThickText>
                    {item.insurance.company +
                      " (" +
                      item.insurance.applicableAge +
                      "세)"}
                  </Grid>
                </CardDataContent>
                {(item.forbidStartDate || item.forbidEndDate) && (
                  <CardDataContent>
                    <Grid container>
                      <ThickText style={{ marginRight: "5px" }}>
                        비활성화
                      </ThickText>
                      {item.forbidStartDate} ~ {item.forbidEndDate}
                    </Grid>
                  </CardDataContent>
                )}
                <Grid container justifyContent="center">
                  <CustomButtonYellow onClick={() => handleOpen(item)}>
                    수정
                  </CustomButtonYellow>
                </Grid>
              </Grid>
            </CardDataBox>
          );
        })}
      </CardDataLayout>
      {open && (
        <VehicleEdit
          type="수정"
          open={open}
          handleClose={handleClose}
          data={data}
          setData={setData}
          refreshData={refreshData}
        />
      )}
    </Grid>
  );
}

export default VehicleManagementCard;
