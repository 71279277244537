export enum CommonRoutes {
  root = "/",
  logout = "/logout",
  login = "/login",
}

export enum AccommodationRoutes {
  root = "/accommodation",
}

export enum AdminRoutes {
  root = "/admin",
  employee = "/admin/employee",
  accommodation = "/admin/accommodation",
  vehicle = "/admin/vehicle",
  qna = "/admin/qna",
  addemployee = "/admin/employee/addemployee",
}

export enum QnaRoutes {
  root = "/qna",
  board = "/qna/board",
}

export enum MypageRoutes {
  root = "/mypage",
}

export enum TestRoutes {
  root = "/test",
}

export enum ReminderRoutes {
  root = "/reminder",
  test = "/reminder/test",
}

export enum VehicleRoutes {
  root = "/vehicle",
  calendar = "/availableVehicle",
}

// 회의실 예약
export enum MeetingRoomRoutes {
  root = "/meetingRoom",
  monthly = "/meetingRoom/monthly",
  responseMail = "/meetingRoom/reservation/:meetingRoomReservationId/response",
}
