import { atom } from "recoil";
import { InitMyINfo } from "./types/initObject";
import { AccountInfo } from "@azure/msal-browser";
import dayjs from "dayjs";
import {
  DailySchedularMeetingRoomResrvationResponseList,
  EditMeetingRoomReservationFormData,
  MeetingRoomReservation,
  SchedularMeetingRoomReservationResponseList,
  WeeklyMeetingRoomReservationList,
} from "./types/meetingRoom/MeetingRoomReservation";
import {
  AvailableMeetingRoomList,
  MeetingRoomList,
} from "./types/meetingRoom/MeetingRoom";

export const accommodationState = atom({
  key: "accommodationState",
  default: [],
});

interface LoadingModalInterface {
  isLoading: boolean;
  text: string;
}

export const LoadingModalInit: LoadingModalInterface = {
  isLoading: false,
  text: "",
};

/**
 *  로딩 Modal
 */
export const loadingModalAtom = atom<LoadingModalInterface>({
  key: "loadingModalAtom",
  default: LoadingModalInit,
});

export const RoleAtom = atom<AccountInfo[]>({
  key: "RoleAtom",
  default: [],
});

export const RoleCheckAtom = atom({
  key: "RoleCheckAtom",
  default: false,
});

export const DatepickerStartEndTimeAtom = atom<Date[]>({
  key: "DatepickerStartEndTimeAtom",
  default: [],
});

export const ReservationStatusAtom = atom({
  key: "ReservationStatusAtom",
  default: 0,
});

export const BranchEmployeeDataAtom = atom({
  key: "BranchEmployeeDataAtom",
  default: { id: 0, email: "" },
});

export const HolidayDataAtom = atom({
  key: "HolidayDataAtom",
  default: [],
});

export const MyDataAtom = atom({
  key: "MyDataAtom",
  default: InitMyINfo,
});

export const PicListAtom = atom({
  key: "PicListAtom",
  default: [],
});

export const ReminderRoleAtom = atom({
  key: "ReminderRoleAtom",
  default: false,
});

export const MypageTabValue = atom({
  key: "MypageTabValue",
  default: 0,
});

export const BranchEmployeeCheck = atom({
  key: "BranchEmployeeCheck",
  default: true,
});

//#region meetingRoom
interface DateStore {
  focusDate: string;
}

export const dateStore = atom<DateStore>({
  key: "DateStore",
  default: { focusDate: dayjs().format("YYYY-MM-DD") },
});

interface MeetingRoomStore {
  roomList: MeetingRoomList;
  availableMeetingRoomList: AvailableMeetingRoomList;
  checkedRoomList: { [key: number]: boolean };
  currentStartTime: string;
  currentEndTime: string;
}

export const meetingRoomStore = atom<MeetingRoomStore>({
  key: "MeetingRoomStore",
  default: {
    roomList: [],
    availableMeetingRoomList: [],
    checkedRoomList: {},
    currentStartTime: "",
    currentEndTime: "",
  },
});

interface MeetingRoomReservationStore {
  listLength: number;
  monthMeetingRoomReservationList: SchedularMeetingRoomReservationResponseList;
  personalMeetingRoomReservationList: WeeklyMeetingRoomReservationList;
  editDailyMeetingRoomReservationList: SchedularMeetingRoomReservationResponseList;
  dailyMeetingRoomReservationList: DailySchedularMeetingRoomResrvationResponseList;
}
export const meetingRoomReservationStore = atom<MeetingRoomReservationStore>({
  key: "MeetingRoomReservationStore",
  default: {
    listLength: 0,
    personalMeetingRoomReservationList: [],
    monthMeetingRoomReservationList: [],
    editDailyMeetingRoomReservationList: [],
    dailyMeetingRoomReservationList: [],
  },
});

export const initialSelectedItem: MeetingRoomReservation = {
  id: -1,
};
interface ModalStore {
  detailOpen: boolean;
  editOpen: boolean;
  selectedItem: MeetingRoomReservation;
  editedItem: EditMeetingRoomReservationFormData;
}
export const modalStore = atom<ModalStore>({
  key: "ModalStore",
  default: {
    detailOpen: false,
    editOpen: false,
    selectedItem: initialSelectedItem,
    editedItem: initialSelectedItem,
  },
});

//#endregion
