import { AlertModalType } from "../../../system/types/modal/Modal";
import Swal from "sweetalert2";

const reservationSuccessModal = (
  type: AlertModalType,
  message?: string,
  content?: string
) => {
  return Swal.fire({
    title:
      "<div style='font-family:Noto sans CJK KR;font-size:20px;margin-bottom:10px'>예약이 완료됐습니다.</div>",
    icon: "success",
    showConfirmButton: false,
    timer: 1500,
  });
};

export default reservationSuccessModal;
