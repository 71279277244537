import { Box, Grid, Modal } from "@mui/material";
import AdminTitle from "../AdminTitle";
import { MaxWidth, ModalStyle } from "../../../styles/theme";
import { useEffect, useState } from "react";
import { EmployeeApi } from "../../../system/api/EmployeeApi";
import { Employee } from "../../../system/types/Employee";
import { ErrorHandler } from "../../../system/ApiService";
import {
  ColumnDirective,
  ColumnsDirective,
  EditSettingsModel,
  GridComponent,
  Inject,
  Page,
  PageSettingsModel,
} from "@syncfusion/ej2-react-grids";
import {
  CustomButtonGreen,
  CustomButtonRed,
  CustomButtonYellow,
} from "../../../styles/button";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import AlertModal from "../../Common/AlertModal";
import { InitEmployee } from "../../../system/types/initObject";
import AddEmployee from "../Employee/AddEmployee";

function BranchEmployeeSetting() {
  const [data, setData] = useState<Employee[]>([]);
  const [modalType, setModalType] = useState("");
  const pageSettings: PageSettingsModel = { pageSize: 10 };
  const Loading = useLoadingDispatch();
  let grid: any;
  const [employeeData, setEmployeeData] = useState<Employee>(InitEmployee);
  // 모달
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setEmployeeData(InitEmployee);
    setOpen(false);
  };

  const editOptions: EditSettingsModel = { allowDeleting: true };
  useEffect(() => {
    refreshEmployeeData();
  }, []);

  function refreshEmployeeData() {
    Loading({ type: "LOADING" });
    EmployeeApi.getEmployee("지사", true)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        const msg = ErrorHandler(err);
        AlertModal("msg", msg);
      })
      .finally(() => {
        Loading({ type: "COMPLETE" });
      });
  }

  function moveToAddEmployee() {
    setModalType("추가");
    setOpen(true);
  }

  function moveToUpdateEmployee(props: Employee) {
    setEmployeeData(props);
    setModalType("수정");
    setOpen(true);
  }

  function template(props: Employee) {
    return (
      <div>
        {props.active === true ? <div>활성화</div> : <div>비활성화</div>}
      </div>
    );
  }

  function activeTemplate(props: Employee) {
    return (
      <div>
        {props.active === true ? (
          <CustomButtonRed onClick={() => changeActive(props)}>
            비활성화
          </CustomButtonRed>
        ) : (
          <CustomButtonGreen onClick={() => changeActive(props)}>
            활성화
          </CustomButtonGreen>
        )}
        <CustomButtonYellow onClick={() => moveToUpdateEmployee(props)}>
          수정
        </CustomButtonYellow>
      </div>
    );
  }
  const changeActive = (props: Employee) => {
    EmployeeApi.updateEmployee({ ...props })
      .then(() => {
        AlertModal("success", "지사 직원 활성화 상태가 변경되었습니다.");
        refreshEmployeeData();
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  };
  return (
    <Grid container item xs={12}>
      <Grid container justifyContent="right">
        <CustomButtonGreen onClick={moveToAddEmployee}>
          계정 추가
        </CustomButtonGreen>
      </Grid>

      <GridComponent
        dataSource={data}
        allowPaging={true}
        height={602}
        editSettings={editOptions}
        pageSettings={pageSettings}
        ref={(g) => (grid = g)}
        style={{ overflowY: "hidden" }}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="workplace"
            headerText="소속"
            width="50"
            textAlign="Center"
          />

          <ColumnDirective
            field="name"
            headerText="이름"
            width="100"
            textAlign="Center"
          />
          <ColumnDirective
            field="email"
            headerText="계정"
            width="150"
            headerTextAlign="Center"
          />
          <ColumnDirective
            field="phone"
            headerText="연락처"
            width="150"
            textAlign="Center"
          />
          <ColumnDirective
            field="active"
            headerText="상태"
            width="50"
            template={template}
            textAlign="Center"
          />
          <ColumnDirective
            field="id"
            headerText="상태 변경"
            width="200"
            template={activeTemplate}
            textAlign="Center"
          />
        </ColumnsDirective>
        <Inject services={[Page]} />
      </GridComponent>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <AddEmployee
            employeeData={employeeData}
            setEmployeeData={setEmployeeData}
            modalType={modalType}
            handleClose={handleClose}
            refreshEmployeeData={refreshEmployeeData}
          />
        </Box>
      </Modal>
    </Grid>
  );
}

export default BranchEmployeeSetting;
