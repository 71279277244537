import { Autocomplete, Grid, TextField } from "@mui/material";
import {
  AppliedItem,
  InputLayout,
  MainTitle,
  RightLayout,
  RaffleContent,
  TextareaBox,
} from "../../../styles/theme";
import { Employee } from "../../../system/types/Employee";
import { CustomButtonGreen } from "../../../styles/button";
import AlertModal from "../../Common/AlertModal";
import { X } from "@phosphor-icons/react";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { RESOURCE_TYPE } from "../../../system/Constants";
import {
  AccommodationInfoTitle,
  EmployeeInfoBox,
} from "../../../styles/accommodationStyle";
import { BlackList } from "../../../system/types/blacklist/BlackList";
import { isoDateFormatter } from "../../Common/IsoDateFormatter";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { useCallback } from "react";
import { ErrorHandler } from "../../../system/ApiService";
import { AxiosError } from "axios";
import { BlackListApi } from "../../../system/api/blacklist/BlackListApi";

function validation(data: BlackList) {
  if (data.employeeId === -1) {
    AlertModal("check", "해당 임직원을 선택해주세요.");
    return false;
  } else if (data.resourceType === "") {
    AlertModal("check", "유형을 선택해주세요.");
    return false;
  } else if (data.reason === "") {
    AlertModal("check", "사유를 입력해주세요.");
    return false;
  } else if (data.endDate === "") {
    AlertModal("check", "종료 일자를 선택해주세요.");
    return false;
  }
  return true;
}

interface AddBlackListProps {
  addBlackListData: BlackList;
  setAddBlackListData: React.Dispatch<React.SetStateAction<BlackList>>;
  handleClose: () => void;
  getBlackList: () => void;
  employeeData: Employee[];
}

function AddBlackList({
  addBlackListData,
  setAddBlackListData,
  handleClose,
  getBlackList,
  employeeData,
}: AddBlackListProps) {
  var submitcheck = false;
  const onChange = (args: any) => {
    setAddBlackListData((prev: BlackList) => ({
      ...prev,
      [(args.target as HTMLInputElement).name]: args.target.value,
    }));
  };

  const onChangeDate = (args: any) => {
    setAddBlackListData((prev: BlackList) => ({
      ...prev,
      endDate: isoDateFormatter(args.value) ?? "",
    }));
  };

  const onSave = useCallback(
    async (dt: BlackList) => {
      if (submitcheck === false && validation(dt)) {
        submitcheck = true;
        try {
          const params = {
            employeeId: dt.employeeId,
            reason: dt.reason,
            resourceType: dt.resourceType,
            endDate: dt.endDate,
          };
          await BlackListApi.postBlackList(params);
          setAddBlackListData({
            employeeId: -1,
            reason: "",
            resourceType: "휴양소",
            endDate: "",
          });
          AlertModal("success", "예약 제한이 추가되었습니다.");
          handleClose();
          getBlackList();
        } catch (err) {
          const msg = ErrorHandler(err as AxiosError);
          AlertModal("msg", msg);
        } finally {
          submitcheck = false;
        }
      }
    },
    [addBlackListData]
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RaffleContent>
            <MainTitle>예약 제한 추가</MainTitle>

            <AppliedItem onClick={handleClose}>
              <X size={32} />
            </AppliedItem>
          </RaffleContent>
        </Grid>
        <Grid item xs={12}>
          <EmployeeInfoBox item xs={12}>
            <InputLayout item xs={12} mt="10px">
              <Grid item xs={12}>
                <AccommodationInfoTitle>이름</AccommodationInfoTitle>
                <Autocomplete
                  options={employeeData} // 직원 목록을 옵션으로 사용
                  getOptionLabel={(option) =>
                    `${option.name} (${option.email})`
                  } // 표시할 텍스트
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  } // 선택 값 비교
                  onChange={(event, newValue) => {
                    setAddBlackListData((prev) => ({
                      ...prev,
                      employeeId: newValue?.id ?? 0, // null 대신 0 할당
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="임직원을 검색해주세요."
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                    />
                  )}
                />
              </Grid>
            </InputLayout>

            <InputLayout item xs={12} mt="10px">
              <Grid item xs={12}>
                <AccommodationInfoTitle>유형</AccommodationInfoTitle>
                <DropDownListComponent
                  dataSource={RESOURCE_TYPE}
                  name="resourceType"
                  value={addBlackListData.resourceType || ""}
                  onChange={onChange}
                  placeholder="유형을 선택해주세요."
                />
              </Grid>
            </InputLayout>

            <InputLayout item xs={12} mt="10px">
              <Grid item xs={12}>
                <AccommodationInfoTitle>사유</AccommodationInfoTitle>
                <TextareaBox
                  name="reason"
                  onChange={onChange}
                  value={addBlackListData.reason || ""}
                  placeholder="사유를 입력해주세요."
                />
              </Grid>
            </InputLayout>

            <InputLayout item xs={12} mt="10px">
              <Grid item xs={12}>
                <AccommodationInfoTitle>종료 날짜</AccommodationInfoTitle>

                <DatePickerComponent
                  name="endDate"
                  placeholder="종료 날짜를 선택해주세요."
                  format="yyyy-MM-dd"
                  locale="ko"
                  onChange={onChangeDate}
                />
              </Grid>
            </InputLayout>
          </EmployeeInfoBox>
        </Grid>

        <Grid item xs={12}>
          <RightLayout>
            <CustomButtonGreen onClick={() => onSave(addBlackListData)}>
              저장
            </CustomButtonGreen>
          </RightLayout>
        </Grid>
      </Grid>
    </>
  );
}

export default AddBlackList;
