import client from "../../ApiService";
import HttpClient from "../../http-client";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _BlackListApi extends _BasicApi {
  // 블랙리스트 조회
  getBlackList<RP>() {
    return this.client.get<RP>(`blacklist`);
  }
  // 블랙리스트 추가
  postBlackList<RQ, RP>(params: RQ) {
    return this.client.post<RQ, RP>(`blacklist`, params);
  }

  //블랙리스트 수정
  updateBlackList<RQ, RP>(
    employeeId: number,
    resourceType: string,
    params: RQ
  ) {
    return this.client.put<RQ, RP>(
      `blacklist?employeeId=${employeeId}&resourceType=${resourceType}`,
      params
    );
  }

  // 블랙리스트 삭제
  deleteBlackList<RQ, RP>(blackListId: number) {
    return this.client.delete<RP>(`blacklist/${blackListId}`);
  }
}

export const BlackListApi = new _BlackListApi(client);
