// Font
export const BasicFontKR = "Noto sans CJK KR";
export const BasicFontEN = "Noto sans";

// MeetingRoom Add
export const TitleFontSize = "1.50rem"; // WEB: 24px
export const SubTitleFontSize = "1.25rem"; // WEB: 20px

export const LargeFontSize = "1.25rem"; // WEB: 20px
export const MiddleFontSize = "1.0rem"; // WEB: 16px
export const SmallFontSize = "0.75rem"; // WEB: 12px

export const ButtonTextSize = "0.875rem"; // WEB: 14px

export const BoldFontWeight = 900;
export const NormalFontWeight = 500;
