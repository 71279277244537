import Swal from "sweetalert2";
import { AlertModalType } from "../../../system/types/modal/Modal";
import { GREENCONTENTS, REDTITLE } from "../../../styles/color";

const questionModal = (
  type: AlertModalType,
  message?: string,
  content?: string
) => {
  return Swal.fire({
    title:
      "<div style='font-family:Noto sans CJK KR;font-size:20px;margin-bottom:10px'>" +
      message +
      "</div>",
    icon: "question",
    confirmButtonText: "예",
    cancelButtonText: "아니오",
    showCancelButton: true,
    confirmButtonColor: GREENCONTENTS,
    cancelButtonColor: REDTITLE,
  });
};

export default questionModal;
