import Swal from "sweetalert2";
import { AlertModalType } from "../../../system/types/modal/Modal";

const checkModal = (
  type: AlertModalType,
  message?: string,
  content?: string
) => {
  return Swal.fire({
    title:
      "<div style='font-family:Noto sans CJK KR;font-size:20px;margin-bottom:10px'>" +
      message +
      "</div>",
    icon: "warning",
    showConfirmButton: false,
    timer: 1500,
  });
};

export default checkModal;
