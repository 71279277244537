import {
  Grid,
  Modal,
  Box,
  styled,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
} from "@mui/material";
import {
  AppliedItem,
  CustomTextField,
  InputBox,
  MainTitle,
  ModalStyle,
  RaffleContent,
} from "../../../styles/theme";
import { X } from "@phosphor-icons/react";
import { CustomButtonGreen } from "../../../styles/button";
import { InsuranceInfo, VehicleInfo } from "../../../system/types/Vehicle";
import { Dispatch, useEffect, useState } from "react";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  VEHICLEFUELTYPE,
  VEHICLESHAPETYPE,
  VEHICLESIZETYPE,
  VEHICLEUNITTYPE,
  VEHICLEUSAGETYPE,
} from "../../../system/Constants";
import { InsuranceApi } from "../../../system/api/InsuranceApi";
import { ErrorHandler } from "../../../system/ApiService";
import AlertModal from "../../Common/AlertModal";

import Autocomplete from "@mui/material/Autocomplete";
import { Employee } from "../../../system/types/Employee";
import { EmployeeApi } from "../../../system/api/EmployeeApi";
import { CustomText } from "../../../styles/CustomText";
import { isoDateFormatter } from "../../Common/IsoDateFormatter";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { VehicleApi } from "../../../system/api/VehicleApi";
import { REDTITLE } from "../../../styles/color";

function validation(data: VehicleInfo) {
  if (data.name === "") {
    AlertModal("check", "차량 이름을 작성해주세요.");
    return false;
  } else if (data.number === "") {
    AlertModal("check", "차량 번호를 작성해주세요.");
    return false;
  } else if (data.usageType === "") {
    AlertModal("check", "차량 용도를 선택해주세요.");
    return false;
  } else if (data.seater === 0) {
    AlertModal("check", "차량 탑승인원을 작성해주세요.");
    return false;
  } else if (isNaN(data.seater)) {
    AlertModal("check", "차량 탑승인원을 숫자만 작성해주세요.");
    return false;
  } else if (data.fuelType === "") {
    AlertModal("check", "차량 연료 종류를 선택해주세요.");
    return false;
  } else if (data.insurance.id === 0) {
    AlertModal("check", "보험 회사를 선택해주세요.");
    return false;
  } else if (data.unitType === "") {
    AlertModal("check", "차량 누적 주행 단위 종류를 선택해주세요.");
    return false;
  } else if (data.sizeType === "") {
    AlertModal("check", "차량 크기 종류를 선택해주세요.");
    return false;
  } else if (data.shapeType === "") {
    AlertModal("check", "차량 종류를 선택해주세요.");
    return false;
  } else if (!data?.manager) {
    AlertModal("check", "담당자를 선택해주세요.");
    return false;
  }
  return true;
}

interface VehicleEditProps {
  type: string;
  open: boolean;
  handleClose: () => void;
  data: VehicleInfo;
  setData: Dispatch<React.SetStateAction<VehicleInfo>>;
  refreshData: () => void;
}

function VehicleEdit({
  type,
  open,
  handleClose,
  data,
  setData,
  refreshData,
}: VehicleEditProps) {
  const [insuranceData, setInsuranceData] = useState<InsuranceInfo[]>([]);
  const [employeeData, setEmployeeData] = useState<Employee[]>([]);
  useEffect(() => {
    InsuranceApi.getInsurance(0, 9999999)
      .then((res) => {
        setInsuranceData(res.data.insurances);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
    EmployeeApi.getEmployee("본사", true)
      .then((res) => {
        setEmployeeData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  const onChange = (args: any) => {
    setData({
      ...data,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };

  const onChangeManager = (newValue: Employee | null) => {
    setData({ ...data, manager: newValue });
  };
  const onChangeDesignatedExecutives = (newValue: Employee | null) => {
    setData({ ...data, designatedExecutives: newValue });
  };
  const onChangeInsurance = (args: any) => {
    const targetValue = args.target.value;
    if (targetValue) {
      const selectedInsurance = insuranceData.filter(
        (item) => item.id === targetValue
      )[0];
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: selectedInsurance,
      });
    }
  };

  const dataFields: Object = {
    value: "id",
    text: "company",
    dataSource: insuranceData,
    child: "children",
  };

  const onChangeDate = (args: any) => {
    if (args.value && args.value[0] && args.value[1]) {
      setData({
        ...data,
        forbidStartDate: isoDateFormatter(new Date(args.value[0])),
        forbidEndDate: isoDateFormatter(new Date(args.value[1])),
      });
    } else {
      setData({
        ...data,
        forbidStartDate: null,
        forbidEndDate: null,
      });
    }
  };

  var submitcheck = false;
  const onSave = () => {
    if (submitcheck === false && validation(data)) {
      submitcheck = true;
      if (data.id) {
        VehicleApi.updateVehicle(data)
          .then(() => {
            handleClose();
            AlertModal("success", "차량이 수정되었습니다.");
            refreshData();
            submitcheck = false;
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          });
      } else {
        VehicleApi.createVehicle(data)
          .then(() => {
            handleClose();
            AlertModal("success", "차량이 생성되었습니다.");
            refreshData();
            submitcheck = false;
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          });
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyle}>
        <Grid item xs={12}>
          <RaffleContent>
            <MainTitle>차량 {type}</MainTitle>
            <AppliedItem onClick={handleClose}>
              <X size={32} />
            </AppliedItem>
          </RaffleContent>
        </Grid>
        <EditContentBox container>
          <Grid container item xs={12} sm={6}>
            이름
            <CustomText fontSize="small" color={REDTITLE}>
              *
            </CustomText>
          </Grid>
          <Grid container item xs={12} sm={6} pl="5px">
            차량번호{" "}
            <CustomText fontSize="small" color={REDTITLE}>
              *
            </CustomText>
          </Grid>
          <Grid item xs={12} sm={6} pr="5px">
            <InputBox
              type="text"
              onChange={onChange}
              name="name"
              value={data.name}
              placeholder="차량 이름을 작성해주세요."
            />
          </Grid>
          <Grid item xs={12} sm={6} pl="5px">
            <InputBox
              type="text"
              onChange={onChange}
              name="number"
              value={data.number || ""}
              placeholder="차량 번호를 작성해주세요."
            />
          </Grid>
          <Grid container item xs={12} sm={6}>
            용도{" "}
            <CustomText fontSize="small" color={REDTITLE}>
              *
            </CustomText>
          </Grid>
          <Grid container item xs={12} sm={6} pl="5px">
            인승{" "}
            <CustomText fontSize="small" color={REDTITLE}>
              *
            </CustomText>
          </Grid>
          <Grid item xs={12} sm={6} pr="5px">
            <DropDownListComponent
              name="usageType"
              dataSource={VEHICLEUSAGETYPE}
              value={data.usageType || ""}
              onChange={onChange}
              placeholder="용도를 선택해주세요."
            />
          </Grid>
          <Grid item xs={12} sm={6} pl="5px">
            <InputBox
              type="text"
              onChange={onChange}
              name="seater"
              value={data.seater || ""}
              placeholder="탑승인원을 숫자만 작성해주세요. (예: 5)"
            />
          </Grid>
          <Grid container item xs={12} sm={6}>
            연료 종류{" "}
            <CustomText fontSize="small" color={REDTITLE}>
              *
            </CustomText>
          </Grid>
          <Grid container item xs={12} sm={6} pl="5px">
            보험{" "}
            <CustomText fontSize="small" color={REDTITLE}>
              *
            </CustomText>
          </Grid>
          <Grid item xs={12} sm={6} pr="5px">
            <DropDownListComponent
              name="fuelType"
              dataSource={VEHICLEFUELTYPE}
              value={data.fuelType || ""}
              onChange={onChange}
              placeholder="연료 종류를 선택해주세요."
            />
          </Grid>
          <Grid item xs={12} sm={6} pl="5px">
            {insuranceData && (
              <DropDownListComponentBox
                locale="ko"
                dataSource={insuranceData}
                showClearButton={true}
                fields={dataFields}
                name="insurance"
                value={data.insurance.id}
                onChange={onChangeInsurance}
                placeholder="보험 회사를 선택해주세요."
                delayUpdate={true}
              />
            )}
          </Grid>
          <Grid container item xs={12} sm={6}>
            누적 주행 거리/시간{" "}
            <CustomText fontSize="small" color={REDTITLE}>
              *
            </CustomText>
          </Grid>
          <Grid container item xs={12} sm={6} pl="5px">
            누적 주행 단위 종류{" "}
            <CustomText fontSize="small" color={REDTITLE}>
              *
            </CustomText>
          </Grid>
          <Grid item xs={12} sm={6} pr="5px">
            <InputBox
              type="text"
              onChange={onChange}
              name="totalMileage"
              value={data.totalMileage}
              placeholder="누적 주행 거리/시간을 작성해주세요."
            />
          </Grid>
          <Grid item xs={12} sm={6} pl="5px">
            <DropDownListComponent
              name="unitType"
              dataSource={VEHICLEUNITTYPE}
              value={data.unitType || ""}
              onChange={onChange}
              placeholder="누적 주행 단위 종류를 선택해주세요."
            />
          </Grid>

          <Grid container item xs={12} sm={6}>
            차량 크기{" "}
            <CustomText fontSize="small" color={REDTITLE}>
              *
            </CustomText>
          </Grid>
          <Grid container item xs={12} sm={6} pl="5px">
            차량 종류{" "}
            <CustomText fontSize="small" color={REDTITLE}>
              *
            </CustomText>
          </Grid>
          <Grid item xs={12} sm={6} pr="5px">
            <DropDownListComponent
              name="sizeType"
              dataSource={VEHICLESIZETYPE}
              value={data.sizeType || ""}
              onChange={onChange}
              placeholder="차량 크기를 선택해주세요."
            />
          </Grid>
          <Grid item xs={12} sm={6} pl="5px">
            <DropDownListComponent
              name="shapeType"
              dataSource={VEHICLESHAPETYPE}
              value={data.shapeType || ""}
              onChange={onChange}
              placeholder="차량 종류를 선택해주세요."
            />
          </Grid>
          <Grid container item xs={12} sm={6}>
            담당자{" "}
            <CustomText fontSize="small" color={REDTITLE}>
              *
            </CustomText>
          </Grid>
          <Grid item xs={12} sm={6} pl="5px">
            임직원 지정
          </Grid>
          <Grid item xs={12} sm={6} pr="5px">
            {employeeData && (
              <>
                <Autocomplete
                  value={data.manager}
                  defaultValue={data.manager}
                  onChange={(event: any, newValue: Employee | null) => {
                    onChangeManager(newValue);
                  }}
                  options={employeeData}
                  getOptionLabel={(option: Employee) => option.name}
                  isOptionEqualToValue={(option: Employee, value: Employee) =>
                    option.id === value.id
                  }
                  renderOption={(props, option: Employee) => (
                    <Box component="li" {...props}>
                      <Grid
                        item
                        xs={12}
                        container
                        justifyContent="space-between"
                      >
                        <Grid item xs={4}>
                          {option.name}
                        </Grid>
                        <Grid item container xs={8} justifyContent="right">
                          {option.jobTitle}{" "}
                          {option.jobTitle && option.jobPosition && "/"}{" "}
                          {option.jobPosition}
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      placeholder="이름으로 검색 후 선택해 주세요."
                    />
                  )}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        label={option.name}
                        {...getTagProps({ index })}
                        variant="outlined"
                      />
                    ))
                  }
                />
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={6} pl="5px">
            {employeeData && (
              <>
                <Autocomplete
                  value={data.designatedExecutives}
                  defaultValue={data.designatedExecutives}
                  onChange={(event: any, newValue: Employee | null) => {
                    onChangeDesignatedExecutives(newValue);
                  }}
                  options={employeeData}
                  getOptionLabel={(option: Employee) => option.name}
                  isOptionEqualToValue={(option: Employee, value: Employee) =>
                    option.id === value.id
                  }
                  renderOption={(props, option: Employee) => (
                    <Box component="li" {...props}>
                      <Grid
                        item
                        xs={12}
                        container
                        justifyContent="space-between"
                      >
                        <Grid item xs={4}>
                          {option.name}
                        </Grid>
                        <Grid item container xs={8} justifyContent="right">
                          {option.jobTitle}{" "}
                          {option.jobTitle && option.jobPosition && "/"}{" "}
                          {option.jobPosition}
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      placeholder="이름으로 검색 후 선택해 주세요."
                    />
                  )}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        label={option.name}
                        {...getTagProps({ index })}
                        variant="outlined"
                      />
                    ))
                  }
                />
              </>
            )}
          </Grid>
          <Grid container item xs={12}>
            비활성화 기간
          </Grid>
          <Grid container item xs={12} sm={6}>
            <DateRangePickerComponent
              id="daterangepicker"
              placeholder="비활성화 기간을 선택해주세요."
              format="yyyy-MM-dd"
              locale="ko"
              delayUpdate={true}
              value={
                data.forbidStartDate && data.forbidEndDate
                  ? [
                      new Date(data.forbidStartDate),
                      new Date(data.forbidEndDate),
                    ]
                  : undefined
              }
              onChange={onChangeDate}
            />
          </Grid>
        </EditContentBox>
        <Grid container justifyContent="right">
          <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
        </Grid>
      </Box>
    </Modal>
  );
}

export default VehicleEdit;

const EditContentBox = styled(Grid)({
  height: "calc(100% - 85px)",
  overflow: "scroll",
});

const DropDownListComponentBox = styled(DropDownListComponent)(() => ({
  border: "0px",
  fontSize: "16px",
  fontWeight: 300,
  fontFamily: "Noto sans CJK KR",
}));
