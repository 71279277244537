import {
  AppliedItem,
  MainTitle,
  MapButton,
  MaxWidth,
  RaffleContent,
  RaffleDay,
} from "../../styles/theme";
import { Divider, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { MapButtonGreen, CustomButtonPrimary } from "../../styles/button";
import MapContainer from "../Common/MapContainer";
import SelectAccommodationBox from "../Common/SelectAccommodationBox";
import { AccommodationApi } from "../../system/api/AccommodationApi";
import {
  AccommodationRezData,
  DetailedAccommodation,
  DetailedAddress,
  detailAddress,
} from "../../system/types/Accommodation";
import {
  InitAccommodationRez,
  InitCreateReservation,
  InitDetailedAccommodation,
  InitEmployee,
} from "../../system/types/initObject";
import { ErrorHandler } from "../../system/ApiService";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  BranchEmployeeDataAtom,
  RoleCheckAtom,
  accommodationState,
} from "../../system/atoms";
import { ResourceApi } from "../../system/api/Resource";
import AccommodationData from "./AccommodationData";
import { useHistory } from "react-router-dom";
import { MypageRoutes } from "../../system/types/type";
import { useLoadingDispatch } from "../../system/context/LoadingContext";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { ChangeEventArgs } from "@syncfusion/ej2-buttons";
import AlertModal from "../Common/AlertModal";
import { X } from "@phosphor-icons/react";
import {
  AccommodationBox,
  AdminBackgroundBox,
  AdminCheckBackgroundBox,
  AdminReservationBox,
  BackgroundBox,
  AdminAccommodationMemoInput,
} from "../../styles/accommodationStyle";

function validation(detailedAddresseId: number, dateRange: Date[]) {
  if (detailedAddresseId === 0) {
    AlertModal("check", "예약할 휴양소를 선택해주세요.");
    return false;
  } else if (dateRange.length === 0) {
    AlertModal("check", "날짜를 선택해주세요.");
    return false;
  }
  return true;
}
interface AccommodationRezProps {
  selectedAccommodationData: AccommodationRezData;
  handleClose: () => void;
  setSelectedAccommodationData: (status: AccommodationRezData) => void;
  calendarMaxDate: string | undefined;
}

function AccommodationRez({
  selectedAccommodationData,
  handleClose,
  setSelectedAccommodationData,
  calendarMaxDate,
}: AccommodationRezProps) {
  const RoleCheck = useRecoilValue(RoleCheckAtom);
  const days = 3;
  const selectAccomodation = (id: number) => {
    if (resourceId !== id) {
      setResourceId(id);
      setGetData(false);
      setDropDownValue("");
    }
    setDetailedAddressesId(0);
  };
  const [resourceId, setResourceId] = useState(0);
  const [resourceData, setResourceData] = useRecoilState(accommodationState);
  const [data, setData] = useState<DetailedAccommodation>(
    InitDetailedAccommodation
  );

  const [DonghoList, setDonghoList]: any = useState([]);
  var now = new Date();
  const [dateRange, setDateRange] = useState<Date[]>([
    new Date(now.getFullYear(), now.getMonth(), now.getDate()),
    new Date(now.getFullYear(), now.getMonth(), now.getDate() + 2),
  ]);
  const [branchEmployeeData, setBranchEmployeeData] = useState<
    { [key: string]: Object }[]
  >([]);

  const history = useHistory();
  const [dateGap, setDateGap] = useState(2);
  const reservationData = InitCreateReservation;
  const [detailedAddressesId, setDetailedAddressesId] = useState(0);
  const Loading = useLoadingDispatch();
  const [dropDownValue, setDropDownValue] = useState("");
  const [adminCheck, setAdminCheck] = useState(false);
  const [adminMemo, setAdminMemo] = useState("");
  const [getData, setGetData] = useState(false);

  var submitcheck = false;
  const minDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  );
  var todayYear = new Date().getFullYear();
  var todayMonth = new Date().getMonth() + 1;
  var todayDate = new Date().getDate();
  var todayDay = new Date().getDay();
  var todayHour = new Date().getHours();
  var todayMinute = new Date().getMinutes();

  var firstMaxDate = new Date(
    todayYear,
    todayMonth,
    todayDate - new Date().getDay() + 9
  );
  var secondMaxDate = new Date(
    todayYear,
    todayMonth,
    todayDate - new Date().getDay() + 2
  );

  const [maxDate, setMaxDate] = useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      new Date().getDate()
    )
  );

  useEffect(() => {
    if (selectedAccommodationData.Data.GroupId) {
      selectAccomodation(selectedAccommodationData.Data.GroupId);
    }
    if (
      selectedAccommodationData.StartTime &&
      isoDateFormatter(new Date(selectedAccommodationData.StartTime)) ===
        calendarMaxDate
    ) {
      if (selectedAccommodationData.Data.Id) {
        setDateGap(1);
      }
      if (selectedAccommodationData.StartTime) {
        var preDateRangeList = [];
        preDateRangeList.push(new Date(selectedAccommodationData.StartTime));
        preDateRangeList.push(
          new Date(
            new Date(selectedAccommodationData.StartTime).getFullYear(),
            new Date(selectedAccommodationData.StartTime).getMonth(),
            new Date(selectedAccommodationData.StartTime).getDate() + 1
          )
        );
        setDateRange(preDateRangeList);
      }
      if (getData === false) {
        getAvailable(
          [
            new Date(selectedAccommodationData.StartTime),
            new Date(
              new Date(selectedAccommodationData.StartTime).getFullYear(),
              new Date(selectedAccommodationData.StartTime).getMonth(),
              new Date(selectedAccommodationData.StartTime).getDate() + 1
            ),
          ],
          selectedAccommodationData.Data.GroupId
        );
      }
    } else {
      if (selectedAccommodationData.Data.Id) {
        var dategap =
          new Date(dateRange[1]).getTime() - new Date(dateRange[0]).getTime();
        var gapday = dategap / (1000 * 60 * 60 * 24);
        setDateGap(gapday);
      }
      if (selectedAccommodationData.StartTime) {
        var preDateRangeList = [];
        preDateRangeList.push(new Date(selectedAccommodationData.StartTime));
        preDateRangeList.push(
          new Date(
            new Date(selectedAccommodationData.StartTime).getFullYear(),
            new Date(selectedAccommodationData.StartTime).getMonth(),
            new Date(selectedAccommodationData.StartTime).getDate() + 2
          )
        );
        setDateRange(preDateRangeList);
      }
      if (getData === false) {
        getAvailable(
          [
            new Date(selectedAccommodationData.StartTime),
            new Date(
              new Date(selectedAccommodationData.StartTime).getFullYear(),
              new Date(selectedAccommodationData.StartTime).getMonth(),
              new Date(selectedAccommodationData.StartTime).getDate() + 2
            ),
          ],
          selectedAccommodationData.Data.GroupId
        );
      }
    }
  }, [selectedAccommodationData]);

  const getAvailable = (selectedDate?: Date[], groupId?: number) => {
    if (resourceId && dateRange.length === 2 && selectedDate !== dateRange) {
      Loading({ type: "LOADING" });
      AccommodationApi.getDetailAddress(
        isoDateFormatter(selectedDate ? selectedDate[0] : dateRange[0]),
        isoDateFormatter(selectedDate ? selectedDate[1] : dateRange[1]),
        groupId ? groupId : resourceId
      )
        .then((res) => {
          const preAvailableData = res.data;
          Loading({ type: "LOADING" });
          AccommodationApi.getAccommodationDetail(resourceId)
            .then((result) => {
              setData(result.data);
              var newDonghoList = [];
              for (var i = 0; i < result.data.detailedAddresses.length; i++) {
                var newDongho: DetailedAddress = {
                  id: 0,
                  address: "",
                  enabled: false,
                  reservationCnt: 0,
                };
                newDongho.id = result.data.detailedAddresses[i].id;
                newDongho.enabled = preAvailableData.find(
                  (dt: detailAddress) =>
                    dt.id === result.data.detailedAddresses[i].id
                )?.available;
                newDongho.reservationCnt = preAvailableData.find(
                  (dt: detailAddress) =>
                    dt.id === result.data.detailedAddresses[i].id
                )?.reservationCount;
                if (result.data.detailedAddresses[i].dong) {
                  if (result.data.detailedAddresses[i].ho) {
                    newDongho.address = `${result.data.detailedAddresses[i].dong}동 ${result.data.detailedAddresses[i].ho}호`;
                  } else {
                    newDongho.address = `${result.data.detailedAddresses[i].dong}동`;
                  }
                  newDonghoList.push(newDongho);
                }
              }
              setDonghoList(newDonghoList);
              setGetData(true);
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            })
            .finally(() => {
              Loading({ type: "COMPLETE" });
            });
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        })
        .finally(() => {
          Loading({ type: "COMPLETE" });
        });
    }
  };

  useEffect(() => {
    if (getData === false) {
      getAvailable();
    }
  }, [resourceId]);

  useEffect(() => {
    if (
      (todayDay === 3 && todayHour === 10 && todayMinute > 4) ||
      (todayDay === 3 && todayHour > 10) ||
      todayDay === 4 ||
      todayDay === 5 ||
      todayDay === 6 ||
      todayDay === 7
    ) {
      setMaxDate(firstMaxDate);
    } else {
      setMaxDate(secondMaxDate);
    }
  }, []);

  const branchEmployee = useRecoilValue(BranchEmployeeDataAtom);

  const onChangeCheckBox = (args: ChangeEventArgs): void => {
    setAdminCheck(!adminCheck);
  };

  const onChangeMemo = (args: any) => {
    setAdminMemo(args.target.value);
  };

  useEffect(() => {
    ResourceApi.getResource("휴양소")
      .then((res) => {
        setResourceData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);
  var dropDownListObject: any;
  const AccommodationFields = { text: "address", value: "id" };
  const onChangeAddress = (args: any) => {
    if (args) {
      const donghovalue = DonghoList.filter(
        (dongho: DetailedAddress) => dongho.id === args.target.value
      );
      if (donghovalue[0] && donghovalue[0].enabled) {
        setDetailedAddressesId(donghovalue[0].id);
        setDropDownValue(donghovalue[0].address);
      } else {
        setDropDownValue("");
        setDetailedAddressesId(0);
        AlertModal(
          "msg",
          "이미 예약 완료된 휴양소입니다. 다른 휴양소를 선택해주세요."
        );
        if (dropDownListObject) {
          dropDownListObject.value = null;
        }
      }
    }
  };

  const onChangeDate = (args: any) => {
    setGetData(false);
    if (args.value) {
      var dategap = args.value[1].getTime() - args.value[0].getTime();
      var gapday = dategap / (1000 * 60 * 60 * 24);
      setDateGap(gapday);
      setDateRange(args.value);

      getAvailable(args.value);
    }
    if (dropDownListObject) {
      dropDownListObject.value = null;
    }
    setDropDownValue("");
    setDetailedAddressesId(0);
  };

  const isoDateFormatter = (date: Date) => {
    if (!date) return undefined;
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}-${month >= 10 ? month : "0" + month}-${
      day >= 10 ? day : "0" + day
    }`;
  };

  const onSave = () => {
    if (submitcheck === false && validation(detailedAddressesId, dateRange)) {
      submitcheck = true;
      if (branchEmployee.id !== null) {
        Loading({ type: "LOADING" });
        AccommodationApi.createReservation({
          ...reservationData,
          type: "휴양소",
          branchEmployee: {
            ...InitEmployee,
            id: branchEmployee.id,
            email: branchEmployee.email,
          },
          startDate: isoDateFormatter(dateRange[0]),
          endDate: isoDateFormatter(dateRange[1]),
          accommodation: {
            ...reservationData.accommodation,
            id: data.id,
            detailedAddresses: {
              ...reservationData.accommodation.detailedAddresses,
              id: detailedAddressesId,
            },
          },
          check: adminCheck,
          memo: adminMemo,
        })
          .then(() => {
            AlertModal("예약완료");
            setAdminMemo("");
            history.push({
              pathname: `${MypageRoutes.root}`,
            });
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          })
          .finally(() => {
            Loading({ type: "COMPLETE" });
            submitcheck = false;
          });
      } else {
        Loading({ type: "LOADING" });
        AccommodationApi.createReservation({
          type: "휴양소",
          startDate: isoDateFormatter(dateRange[0]),
          endDate: isoDateFormatter(dateRange[1]),
          accommodation: {
            ...reservationData.accommodation,
            id: data.id,
            detailedAddresses: {
              ...reservationData.accommodation.detailedAddresses,
              id: detailedAddressesId,
            },
          },
          check: adminCheck,
          memo: adminMemo,
        })
          .then(() => {
            AlertModal("예약완료");
            setAdminMemo("");
            history.push({
              pathname: `${MypageRoutes.root}`,
            });
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          })
          .finally(() => {
            Loading({ type: "COMPLETE" });
            submitcheck = false;
          });
      }
    }
  };

  const MoveToMap = (address: string) => {
    window.open(`https://map.naver.com/v5/search/${address}`);
  };

  function onHandleClose() {
    setGetData(false);
    handleClose();
    setAdminMemo("");
    setSelectedAccommodationData(InitAccommodationRez);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <RaffleContent>
          <MainTitle>휴양소 예약하기</MainTitle>
          <AppliedItem onClick={onHandleClose}>
            <X size={32} />
          </AppliedItem>
        </RaffleContent>
      </Grid>
      <Grid item xs={12}>
        <AccommodationBox>
          <SelectAccommodationBox
            data={resourceData}
            resourceId={resourceId}
            selectAccomodation={selectAccomodation}
            selectedResourceId={selectedAccommodationData.Data.GroupId}
          />
        </AccommodationBox>

        <AccommodationBox>
          <Grid item xs={1}></Grid>
          <Grid item xs={8}>
            <DateRangePickerComponent
              id="daterangepicker"
              placeholder="날짜를 선택해주세요."
              min={minDate}
              max={maxDate}
              maxDays={days}
              format="yyyy-MM-dd"
              locale="ko"
              delayUpdate={true}
              value={dateRange}
              onChange={onChangeDate}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs={2}>
            {dateGap > 0 ? `${dateGap}박 ${dateGap + 1}일` : ``}
          </Grid>
        </AccommodationBox>
        <AccommodationBox>
          <Grid item xs={1}></Grid>
          {resourceId && dateRange ? (
            <Grid item xs={10}>
              <DropDownListComponent
                id="ddlelement"
                dataSource={DonghoList}
                placeholder="예약할 휴양소와 이용 기간을 먼저 선택해주세요."
                onChange={onChangeAddress}
                value={dropDownValue}
                delayUpdate={true}
                fields={AccommodationFields}
                enabled={true}
                ref={(scope) => {
                  dropDownListObject = scope;
                }}
              />
            </Grid>
          ) : (
            <Grid item xs={10}>
              <DropDownListComponent
                id="ddlelement"
                dataSource={DonghoList}
                placeholder="예약할 휴양소과 이용 기간을 먼저 선택해주세요."
                onChange={onChangeAddress}
                value={dropDownValue}
                delayUpdate={true}
                fields={AccommodationFields}
                enabled={false}
                ref={(scope) => {
                  dropDownListObject = scope;
                }}
              />
            </Grid>
          )}

          <Grid item xs={1}></Grid>
        </AccommodationBox>
        {RoleCheck && (
          <AdminReservationBox>
            <CheckBoxComponent
              name="check"
              onChange={onChangeCheckBox}
              checked={adminCheck}
              label="관리자 예약"
            />
          </AdminReservationBox>
        )}
        {adminCheck && (
          <RaffleDay>
            <AdminAccommodationMemoInput
              placeholder="관리자 예약 메모"
              onChange={onChangeMemo}
            />
          </RaffleDay>
        )}
        <AdminReservationBox>
          <CustomButtonPrimary onClick={onSave}>예약 신청</CustomButtonPrimary>
        </AdminReservationBox>
      </Grid>
      <Grid item xs={12}>
        {RoleCheck ? (
          <>
            {adminCheck ? <AdminCheckBackgroundBox /> : <AdminBackgroundBox />}
          </>
        ) : (
          <BackgroundBox />
        )}
      </Grid>
      <Grid item xs={12}>
        <MaxWidth>
          {resourceId ? (
            <>
              {data.latitude && data.longitude ? (
                <>
                  <MapContainer
                    latitude={data.latitude}
                    longitude={data.longitude}
                  />
                  <MapButton>
                    <MapButtonGreen onClick={() => MoveToMap(data.address)}>
                      네이버 지도로 이동
                    </MapButtonGreen>
                  </MapButton>
                </>
              ) : null}
              <Grid item xs={12}>
                <AccommodationData data={data} />
              </Grid>
            </>
          ) : null}
        </MaxWidth>
      </Grid>
    </Grid>
  );
}

export default AccommodationRez;
