import { Grid, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { MaxWidth } from "../../styles/theme";
import AdminTitle from "./AdminTitle";
import BranchEmployeeSetting from "./Employee/BranchEmployeeSetting";
import EmployeeBlackList from "./Employee/EmployeeBlackList";

function AdminEmployeeTable() {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MaxWidth>
          <AdminTitle titleName="직원 관리" />
        </MaxWidth>
      </Grid>
      <Grid item xs={12}>
        <MaxWidth>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="지사 직원" value={0} />
            <Tab label="예약 제한" value={1} />
          </Tabs>
          {value === 0 && <BranchEmployeeSetting />}
          {value === 1 && <EmployeeBlackList />}
        </MaxWidth>
      </Grid>
    </Grid>
  );
}

export default AdminEmployeeTable;
