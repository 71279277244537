import { Grid } from "@mui/material";
import {
  AppliedItem,
  InputLayout,
  MainTitle,
  RightLayout,
  RaffleContent,
  TextareaBox,
} from "../../../styles/theme";
import { CustomButtonGreen } from "../../../styles/button";
import AlertModal from "../../Common/AlertModal";
import { X } from "@phosphor-icons/react";
import {
  AccommodationInfoTitle,
  EmployeeInfoBox,
} from "../../../styles/accommodationStyle";
import { BlackList } from "../../../system/types/blacklist/BlackList";
import { isoDateFormatter } from "../../Common/IsoDateFormatter";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { useCallback } from "react";
import { ErrorHandler } from "../../../system/ApiService";
import { AxiosError } from "axios";
import { BlackListApi } from "../../../system/api/blacklist/BlackListApi";

function validation(data: BlackList) {
  if (data.employeeId === -1) {
    AlertModal("check", "해당 임직원을 선택해주세요.");
    return false;
  } else if (data.resourceType === "") {
    AlertModal("check", "유형을 선택해주세요.");
    return false;
  } else if (data.reason === "") {
    AlertModal("check", "사유를 입력해주세요.");
    return false;
  } else if (data.endDate === "") {
    AlertModal("check", "종료 일자를 선택해주세요.");
    return false;
  }
  return true;
}

interface EditBlackListProps {
  addBlackListData: BlackList;
  setAddBlackListData: React.Dispatch<React.SetStateAction<BlackList>>;
  handleClose: () => void;
  getBlackList: () => void;
}

function EditBlackList({
  addBlackListData,
  setAddBlackListData,
  handleClose,
  getBlackList,
}: EditBlackListProps) {
  var submitcheck = false;
  const onChange = (args: any) => {
    setAddBlackListData((prev: BlackList) => ({
      ...prev,
      [(args.target as HTMLInputElement).name]: args.target.value,
    }));
  };

  const onChangeDate = (args: any) => {
    setAddBlackListData((prev: BlackList) => ({
      ...prev,
      endDate: isoDateFormatter(args.value) ?? "",
    }));
  };

  const onSave = useCallback(
    async (dt: BlackList) => {
      if (submitcheck === false && validation(dt)) {
        submitcheck = true;
        try {
          const params = {
            reason: dt.reason,
            endDate: dt.endDate,
          };
          await BlackListApi.updateBlackList(
            dt.employeeId,
            dt.resourceType,
            params
          );
          setAddBlackListData({
            employeeId: -1,
            reason: "",
            resourceType: "휴양소",
            endDate: "",
          });
          AlertModal("success", "예약 제한이 수정되었습니다.");
          handleClose();
          getBlackList();
        } catch (err) {
          const msg = ErrorHandler(err as AxiosError);
          AlertModal("msg", msg);
        } finally {
          submitcheck = false;
        }
      }
    },
    [addBlackListData]
  );
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RaffleContent>
            <MainTitle>예약 제한 수정</MainTitle>

            <AppliedItem onClick={handleClose}>
              <X size={32} />
            </AppliedItem>
          </RaffleContent>
        </Grid>
        <Grid item xs={12}>
          <EmployeeInfoBox item xs={12}>
            <InputLayout item xs={12} mt="10px">
              <Grid item xs={12}>
                <AccommodationInfoTitle>이름</AccommodationInfoTitle>
                <Grid>{addBlackListData.employeeName ?? "-"}</Grid>
              </Grid>
            </InputLayout>

            <InputLayout item xs={12} mt="10px">
              <Grid item xs={12}>
                <AccommodationInfoTitle>유형</AccommodationInfoTitle>
                <Grid>{addBlackListData.resourceType ?? "-"}</Grid>
              </Grid>
            </InputLayout>

            <InputLayout item xs={12} mt="10px">
              <Grid item xs={12}>
                <AccommodationInfoTitle>사유</AccommodationInfoTitle>
                <TextareaBox
                  name="reason"
                  onChange={onChange}
                  value={addBlackListData.reason || ""}
                  placeholder="사유를 입력해주세요."
                />
              </Grid>
            </InputLayout>

            <InputLayout item xs={12} mt="10px">
              <Grid item xs={12}>
                <AccommodationInfoTitle>종료 날짜</AccommodationInfoTitle>

                <DatePickerComponent
                  name="endDate"
                  value={new Date(addBlackListData.endDate)}
                  placeholder="종료 날짜를 선택해주세요."
                  format="yyyy-MM-dd"
                  locale="ko"
                  onChange={onChangeDate}
                />
              </Grid>
            </InputLayout>
          </EmployeeInfoBox>
        </Grid>

        <Grid item xs={12}>
          <RightLayout>
            <CustomButtonGreen onClick={() => onSave(addBlackListData)}>
              저장
            </CustomButtonGreen>
          </RightLayout>
        </Grid>
      </Grid>
    </>
  );
}

export default EditBlackList;
