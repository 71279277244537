import { styled } from "@mui/material/styles";

import {
  GRAYPALEBACKGROUND2,
  GRAYPALEBOX,
  GRAYPALESTROKE,
  GRAYPALETITLE,
  PRIMARY,
} from "./color";

export const CustomButtonCommon = styled("button")({
  fontFamily: "Noto sans CJK KR",
  backgroundColor: `#ffffff`,
  color: "#4B4F5A",
  border: "1px solid #DCDFE3",
  boxShadow: "none",
  borderRadius: "5px",
  width: "16rem",
  height: "3rem",
  fontWeight: 500,
  cursor: "pointer",
  margin: "1rem 1.3rem",
  overflow: "hidden",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: `#F4F4F4`,
    boxShadow: "none",
    color: "#4B4F5A",
  },
});

export const CustomButtonCommonDisabled = styled("button")({
  fontFamily: "Noto sans CJK KR",
  backgroundColor: `#ffffff`,
  color: "#B2B4B8",
  border: "1px solid #DCDFE3",
  boxShadow: "none",
  borderRadius: "5px",
  width: "16rem",
  height: "3rem",
  fontWeight: 500,
  cursor: "default",
  margin: "1rem 1.3rem",
  overflow: "hidden",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: `#ffffff`,
    boxShadow: "none",
    color: "#B2B4B8",
    border: "1px solid #DCDFE3",
    cursor: "default",
  },
});

export const CustomButtonPrimary = styled("button")({
  fontFamily: "Noto sans CJK KR",
  backgroundColor: `${PRIMARY}`,
  color: "#ffffff",
  border: "1px solid #DCDFE3",
  boxShadow: "none",
  borderRadius: "5px",
  width: "16rem",
  height: "3rem",
  fontWeight: 500,
  cursor: "pointer",
  margin: "1rem",
  zIndex: "999",
  overflow: "hidden",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: `${PRIMARY}`,
    boxShadow: "none",
    color: "#ffffff",
  },
});

export const CustomButtonDisabled = styled("button")({
  fontFamily: "Noto sans CJK KR",
  backgroundColor: `${GRAYPALESTROKE}`,
  color: "#ffffff",
  border: "0px",
  boxShadow: "none",
  borderRadius: "5px",
  width: "7rem",
  height: "2.5rem",
  fontWeight: 500,
  cursor: "default",
  margin: "5px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  "&:hover": {
    color: "#ffffff",
    boxShadow: "none",
    cursor: "default",
  },
  "&:focus": {
    color: "#ffffff",
    boxShadow: "none",
    cursor: "default",
  },
});

export const CustomButtonGray = styled("button")({
  fontFamily: "Noto sans CJK KR",
  backgroundColor: GRAYPALEBACKGROUND2,
  color: GRAYPALETITLE,
  border: "0px",
  boxShadow: "none",
  borderRadius: "5px",
  width: "7rem",
  height: "2.5rem",
  fontWeight: 500,
  cursor: "pointer",
  margin: "5px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: GRAYPALEBOX,
    boxShadow: "none",
    color: GRAYPALETITLE,
  },
});

export const CustomButtonWhite = styled("button")({
  fontFamily: "Noto sans CJK KR",
  backgroundColor: `#ffffff`,
  color: GRAYPALETITLE,
  border: "1px solid #DCDFE3",
  boxShadow: "none",
  borderRadius: "5px",
  width: "7rem",
  height: "2.5rem",
  fontWeight: 500,
  cursor: "pointer",
  margin: "5px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: `#DCDFE3`,
    boxShadow: "none",
    color: "#ffffff",
  },
});

export const CustomButtonBlue = styled("button")({
  fontFamily: "Noto sans CJK KR",
  backgroundColor: `#F0F6FF`,
  color: "#4C8DF5",
  boxShadow: "none",
  borderRadius: "5px",
  width: "7rem",
  height: "2.5rem",
  cursor: "pointer",
  fontWeight: 500,
  border: "0px",
  margin: "5px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: "#E6EFFF",
    boxShadow: "none",
    color: "#4C8DF5",
  },
});

export const CustomButtonRed = styled("button")({
  fontFamily: "Noto sans CJK KR",
  backgroundColor: `#FFF0F3`,
  color: "#F25C75",
  boxShadow: "none",
  borderRadius: "5px",
  cursor: "pointer",
  fontWeight: 500,
  width: "7rem",
  height: "2.5rem",
  border: "0px",
  margin: "5px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: "#FFE6EB",
    boxShadow: "none",
    color: "#F25C75",
  },
});

export const CustomButtonGreen = styled("button")({
  fontFamily: "Noto sans CJK KR",
  backgroundColor: `#F0FAF7`,
  color: "#47B998",
  boxShadow: "none",
  borderRadius: "5px",
  width: "7rem",
  height: "2.5rem",
  cursor: "pointer",
  fontWeight: 500,
  border: "0px",
  margin: "5px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: "#E4F7F1",
    boxShadow: "none",
    color: "#47B998",
  },
});

export const CustomButtonYellow = styled("button")({
  fontFamily: "Noto sans CJK KR",
  backgroundColor: "#fff9c4",
  color: "#F59210",
  boxShadow: "none",
  borderRadius: "5px",
  width: "7rem",
  height: "2.5rem",
  cursor: "pointer",
  fontWeight: 500,
  border: "0px",
  margin: "5px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: "#fff59d",
    boxShadow: "none",
    color: "#F59210",
  },
});

export const MapButtonGreen = styled("button")({
  fontFamily: "Noto sans CJK KR",
  backgroundColor: `#F0FAF7`,
  color: "#47B998",
  boxShadow: "none",
  borderRadius: "5px",
  width: "16rem",
  height: "3rem",
  cursor: "pointer",
  fontWeight: 500,
  margin: "1rem",
  border: "0px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: "#E4F7F1",
    boxShadow: "none",
    color: "#47B998",
  },
});

export const MapButtonYellow = styled("button")({
  fontFamily: "Noto sans CJK KR",
  backgroundColor: "#fff9c4",
  color: "#F59210",
  boxShadow: "none",
  borderRadius: "5px",
  width: "14rem",
  height: "3rem",
  cursor: "pointer",
  fontWeight: 500,
  margin: "0.5rem",
  border: "0px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: "#fff59d",
    boxShadow: "none",
    color: "#F59210",
  },
});

export const CustomButtonMiddleWhite = styled("button")({
  fontFamily: "Noto sans CJK KR",
  backgroundColor: `#ffffff`,
  color: GRAYPALETITLE,
  border: "1px solid #DCDFE3",
  boxShadow: "none",
  borderRadius: "5px",
  width: "9rem",
  height: "2.5rem",
  fontWeight: 500,
  cursor: "pointer",
  margin: "5px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: `#DCDFE3`,
    boxShadow: "none",
    color: "#ffffff",
  },
});
