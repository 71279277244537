import { AlertModalType } from "../../system/types/modal/Modal";
import checkModal from "./Modal/checkModal";
import errorModal from "./Modal/errorModal";
import memoModal from "./Modal/memoModal";

import messageModal from "./Modal/messageModal";
import questionModal from "./Modal/questionModal";
import reservationSuccessModal from "./Modal/reservationSuccessModal";
import successModal from "./Modal/successModal";

const SwitchedModal = (
  type: AlertModalType,
  message?: string,
  content?: string
) => {
  switch (type) {
    case "예약완료":
      return reservationSuccessModal(type, message, content);
    case "success":
      return successModal(type, message, content);
    case "msg":
      return messageModal(type, message, content);
    case "err":
      return errorModal(type, message, content);
    case "check":
      return checkModal(type, message, content);
    case "question":
      return questionModal(type, message, content);
    case "memo":
      return memoModal(type, message, content);
  }
};

const AlertModal = (
  type: AlertModalType,
  message?: string,
  content?: string
) => {
  return SwitchedModal(type, message, content);
};

export default AlertModal;
