import { useCallback, useEffect, useState } from "react";
import {
  BlackList,
  BlackListResponseList,
} from "../../../system/types/blacklist/BlackList";
import { BlackListApi } from "../../../system/api/blacklist/BlackListApi";
import { ErrorHandler } from "../../../system/ApiService";
import { AxiosError } from "axios";
import AlertModal from "../../Common/AlertModal";
import { Box, Grid, Modal, Tooltip } from "@mui/material";
import {
  CustomButtonGreen,
  CustomButtonRed,
  CustomButtonYellow,
} from "../../../styles/button";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  PageSettingsModel,
} from "@syncfusion/ej2-react-grids";
import { SmallModalStyle } from "../../../styles/theme";
import AddBlackList from "./AddBlackList";
import { Employee } from "../../../system/types/Employee";
import { EmployeeApi } from "../../../system/api/EmployeeApi";
import Swal from "sweetalert2";
import { GREENCONTENTS, REDTITLE } from "../../../styles/color";
import EditBlackList from "./EditBlackList";

function EmployeeBlackList() {
  const [blackListData, setBlackListData] = useState<BlackListResponseList>([]);
  const [addBlackListData, setAddBlackListData] = useState<BlackList>({
    employeeId: -1,
    reason: "",
    resourceType: "휴양소",
    endDate: "",
  });
  const [employeeData, setEmployeeData] = useState<Employee[]>([]);
  const [modalState, setModalState] = useState("Create");
  // 그리드 설정
  const pageSettings: PageSettingsModel = { pageSize: 10 };
  let grid: any;

  // 모달
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const getEmployeData = useCallback(() => {
    EmployeeApi.getEmployee("전체", true)
      .then((res) => {
        setEmployeeData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  const getBlackList = useCallback(async () => {
    try {
      const { data } = await BlackListApi.getBlackList<BlackListResponseList>();
      setBlackListData(data);
    } catch (err) {
      let msg = ErrorHandler(err as AxiosError);
      AlertModal("msg", msg);
    }
  }, []);

  useEffect(() => {
    getBlackList();
    getEmployeData();
  }, []);

  const moveToAddEmployee = useCallback(() => {
    setModalState("Create");
    setOpen(true);
  }, [setModalState, setOpen]);

  function template(props: BlackList) {
    return (
      <div>
        {props.resourceType === "ACCOMMODATION" ? (
          <div>휴양소</div>
        ) : (
          <div>기타</div>
        )}
      </div>
    );
  }

  function templateReason(props: BlackList) {
    return (
      <Tooltip
        title={
          <div
            style={{
              whiteSpace: "pre-wrap",
            }}
          >
            {props.reason}
          </div>
        }
        placement="top"
        arrow
      >
        <span>{props.reason}</span>
      </Tooltip>
    );
  }

  const moveToUpdateEmployee = useCallback(
    (props: BlackList) => {
      setModalState("Edit");
      setOpen(true);
      setAddBlackListData({
        employeeId: props.employeeId,
        employeeName: props.employeeName,
        endDate: props.endDate,
        reason: props.reason,
        resourceType: "휴양소",
      });
    },
    [setOpen, setAddBlackListData, setModalState]
  );

  const onRemoveBlackList = useCallback(
    (props: BlackList) => {
      Swal.fire({
        title: "정말 삭제하시겠습니까?",
        text: "선택하신 예약 제한이 삭제됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: GREENCONTENTS,
        cancelButtonColor: REDTITLE,
      }).then((result) => {
        if (result.isConfirmed && props?.id) {
          BlackListApi.deleteBlackList(props.id)
            .then(() => {
              getBlackList();
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("err", msg, "휴양소 삭제 중 오류가 발생했습니다.");
            });
        }
      });
    },
    [getBlackList]
  );

  function changeTemplate(props: any) {
    return (
      <div>
        <CustomButtonYellow onClick={() => moveToUpdateEmployee(props)}>
          수정
        </CustomButtonYellow>
        <CustomButtonRed onClick={() => onRemoveBlackList(props)}>
          삭제
        </CustomButtonRed>
      </div>
    );
  }
  return (
    <Grid container item xs={12}>
      <Grid container justifyContent="right">
        <CustomButtonGreen onClick={moveToAddEmployee}>
          제한 추가
        </CustomButtonGreen>
      </Grid>

      <Grid container item xs={12}>
        <GridComponent
          dataSource={blackListData}
          allowPaging={true}
          height={602}
          pageSettings={pageSettings}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="resourceType"
              headerText="유형"
              width="50"
              textAlign="Center"
              template={template}
              headerTextAlign="Center"
            />
            <ColumnDirective
              field="employeeName"
              headerText="이름"
              width="50"
              textAlign="Center"
              headerTextAlign="Center"
            />
            <ColumnDirective
              field="endDate"
              headerText="종료 날짜"
              width="50"
              textAlign="Center"
              headerTextAlign="Center"
            />
            <ColumnDirective
              field="reason"
              headerText="사유"
              width="100"
              textAlign="Left"
              headerTextAlign="Center"
              template={templateReason}
            />
            <ColumnDirective
              field="id"
              headerText="예약 제한 변경"
              width="80"
              template={changeTemplate}
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Page]} />
        </GridComponent>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={SmallModalStyle}>
          {modalState === "Create" ? (
            <AddBlackList
              addBlackListData={addBlackListData}
              setAddBlackListData={setAddBlackListData}
              handleClose={handleClose}
              getBlackList={getBlackList}
              employeeData={employeeData}
            />
          ) : (
            <EditBlackList
              addBlackListData={addBlackListData}
              setAddBlackListData={setAddBlackListData}
              handleClose={handleClose}
              getBlackList={getBlackList}
            />
          )}
        </Box>
      </Modal>
    </Grid>
  );
}

export default EmployeeBlackList;
